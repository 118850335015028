import React, { useState, useEffect, useContext } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useSignIn } from 'react-auth-kit';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { useSignOut } from 'react-auth-kit';
import { useFormik } from 'formik';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { AppContext, APP_STATE } from '../../AppContext';

import { loginRequest } from '../../api/v1/auth-api';

import {
    validateUsername,
    validateEmail,
    validatePassword
} from './validators/validators';

import { Header } from '../../components/header';
import { ReactComponent as SeekyPageLogo } from '../../assets/seeky-page-logo.svg';
import { getBillingPlanUUID } from '../../helpers/billing-plan';
import { SIGN_UP_KEY } from '../../constants';

import { createRequest } from '../../api/v1/auth-api';

import '../common/styles.css';
import './styles.css';

export const SignUpPage = () => {
    const signOut = useSignOut();
    const [ searchParams ] = useSearchParams();
    
    const plan = searchParams.get('plan');
    const billing = searchParams.get('billing');
    const uuid = getBillingPlanUUID({ plan, billing });

    localStorage.setItem(SIGN_UP_KEY, uuid);

    useEffect(() => {
        signOut();
    }, []); // eslint-disable-line

    return (
        <div className="Page SignUpPage">
            <Header />
            <Body />
        </div>
    );
};

const Body = () => {
    const [ isSubmitOk, setSubmitOk ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    
    const navigate = useNavigate();
    const signIn = useSignIn();
    const appContext = useContext(AppContext);

    const handleSubmitOk = () => setSubmitOk(true);
    const handleChangeNewUserInfo = ({ email, password }) => {
        setEmail(email);
        setPassword(password);
    };

    return (
        <div className="Page-body">
            <div className="Page-card">
                {
                    isSubmitOk
                        ? <Login
                            appContext={appContext}
                            navigate={navigate}
                            signIn={signIn}
                            email={email}
                            password={password}
                        />
                        : <SignUpForm
                            onSubmitOk={handleSubmitOk}
                            onChangeNewUserInfo={handleChangeNewUserInfo}
                        /> }
            </div>
        </div>
    );
};

const Login = ({ appContext, navigate, signIn, email, password }) => {
    const { setAppState, setAppToken, setAppUser } = appContext;

    loginRequest({ email, password })
        .then(responseData => {
            const token = {
                token: responseData?.token,
                type: responseData?.type
            };

            signIn({
                token: token.token,
                tokenType: token.type,
                expiresIn: 3600,
                authState: { email }
            });
            
            setAppToken(token);
            setAppState(APP_STATE.INDEX);
            setAppUser({ id: '', email: '', isActive: false, isVerified: false, firstName: '', lastName: '', hasSubscription: false });
            navigate('/');
        })
        .catch(() => {
            navigate('/login');
        });

    return (
        <div>
            <Spin indicator={
                <LoadingOutlined
                    style={{ fontSize: 24 }}
                    spin
                />
            } />
            <p style={{ paddingTop: '1em' }}>
                Please wait...
            </p>
        </div>
    );
};

const SignUpForm = ({ onSubmitOk, onChangeNewUserInfo }) => {
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ usernameError, setUsernameError ] = useState('');
    const [ emailError, setEmailError ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ checkboxError, setCheckboxError ] = useState('');

    const onSubmit = (values) => {
        let isError = false;

        [
            { value: values.username, validator: validateUsername, setMsg: setUsernameError },
            { value: values.email, validator: validateEmail, setMsg: setEmailError },
            { value: values.password, validator: validatePassword, setMsg: setPasswordError }
        ]
            .map(item => {
                const result = item.validator(item.value);

                if (result) {
                    isError = true;
                }
                item.setMsg(result);

                return result;
            });

        if (!values.isGdprAccepted) {
            isError = true;
            setCheckboxError('You must agree Private Policy');
        } else {
            setCheckboxError('');
        }

        !isError && createRequest(values)
            .then(() => {
                setErrorMessage('');
                onChangeNewUserInfo({
                    email: values.email,
                    password: values.password
                });
                onSubmitOk();
            })
            .catch(errorData => {
                setErrorMessage(errorData);
            });
    };

    const formik = useFormik({
        initialValues: {
            username: '',
            email: '',
            password: '',
            isGdprAccepted: false,
            isMailingsAccepted: false
        },
        onSubmit
    });

    return (
        <div>
            <div className="Page-logo">
                <SeekyPageLogo />
            </div>
            <h1>Create an account</h1>
            <p>Start your 3-day free trial.</p>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        id="username"
                        className={usernameError ? 'error-bounds' : 'bounds'}
                        type="text"
                        placeholder="Enter your name"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                    />
                    { usernameError
                        ? <div className="error-text">{usernameError}</div>
                        : null
                    }
                </Form.Group>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        id="email"
                        className={emailError ? 'error-bounds' : 'bounds'}
                        type="email"
                        placeholder="Enter your email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />
                    { emailError
                        ? <div className="error-text">{emailError}</div>
                        : null
                    }
                </Form.Group>
                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        id="password"
                        className={passwordError ? 'error-bounds' : 'bounds'}
                        type="password"
                        placeholder="Create a password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                    />
                    { passwordError
                        ? <div className="error-text mg-bm">{passwordError}</div>
                        : <p className="info gr">Must be at least 8 characters.</p>
                    }
                </Form.Group>
                <div className="check">
                    <Form.Check
                        id="isGdprAccepted"
                        type={'checkbox'}
                        label={<div>
                            By signing up, I acknowledge the <Link className="link" to="https://www.seeky.ai/privacy-policy">Privacy Policy</Link>
                        </div>}
                        value={formik.values.isGdprAccepted}
                        onChange={formik.handleChange}
                        required
                    />
                </div>
                <div className="check">
                    <Form.Check
                        id="isMailingsAccepted"
                        type={'checkbox'}
                        label={<div>I agree to receive Marketing mailings</div>}
                        value={formik.values.isMailingsAccepted}
                        onClick={formik.handleChange}
                    />
                </div>
                { checkboxError
                    ? <div className="error-message">{checkboxError}</div>
                    : null
                }
                <Button className="form-btn" onClick={formik.handleSubmit}>
                    Sign up
                </Button>
                { errorMessage
                    ? <div className="error-message mg-tp">{errorMessage}</div>
                    : null
                }
                <div className="footer-block">
                    <p className="info cntr">
                        Already have an account? <Link className="link" to="/login">Log in</Link>
                    </p>
                </div>
            </Form>
        </div>
    );
};
