import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useSignOut, useIsAuthenticated, useAuthHeader } from 'react-auth-kit';

import { AppContext, APP_STATE } from '../../AppContext';
import { logoutRequest } from '../../api/v1/auth-api';

import { MobileMenuIcon } from './mobile-menu-icon';
import { ReactComponent as SeekyLogo } from './assets/seeky-logo.svg';

import {
    ROOT_URL,
    SEEKY_URL_FEATURES,
    SEEKY_URL_BENEFITS,
    SEEKY_URL_HOW_IF_WORKS,
    SEEKY_URL_PRICING,
    MOBILE_WIDTH_VALUE
} from '../../constants/constants';

import './styles.css';

export const Header = ({ isSpecialBackground }) => {
    const cnSpecial = isSpecialBackground ? 'Header__special' : '';

    const { setAppState, setAppToken, setAppUser } = useContext(AppContext);
    const [ mobileMenuIsOpened, setMobileMenuIsOpened ] = useState(false);
    const [ width, setWidth ] = useState(window.innerWidth);
    const handleMobileMenuClick = () => setMobileMenuIsOpened(!mobileMenuIsOpened);

    const authHeader = useAuthHeader();
    const isAuthenticated = useIsAuthenticated();
    const signOut = useSignOut();
    const navigate = useNavigate();

    const handleLogout = () => {
        logoutRequest({ auth: authHeader() })
            .catch(errorData => console.log('logout error:', errorData))
            .finally(() => {
                setAppState(APP_STATE.LOGIN);
                setAppToken({ token: '', type: '' });
                setAppUser({ id: '', email: '', isActive: false, isVerified: false, firstName: '', lastName: '' });
                signOut();
                navigate('/login');
            });
    };

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        width > MOBILE_WIDTH_VALUE && mobileMenuIsOpened && setMobileMenuIsOpened(false);
    }, [ width, mobileMenuIsOpened ]);

    return (
        <header className={`Header ${cnSpecial}`}>
            <DesktopHeader
                isSpecialBackground={isSpecialBackground}
                isAuthenticated={isAuthenticated}
                logout={handleLogout}
            />
            <MobileHeader
                isSpecialBackground={isSpecialBackground}
                handler={handleMobileMenuClick}
            />
            {mobileMenuIsOpened && (
                <MobileMenu
                    isAuthenticated={isAuthenticated}
                    logout={handleLogout}
                />
            )}
        </header>
    );
};

const DesktopHeader = ({ isSpecialBackground, isAuthenticated, logout }) => {
    const cnSpecial = isSpecialBackground ? 'Header-container__special' : '';

    return (
        <div className={`Header-container ${cnSpecial}`}>
            <div className="Header-left">
                <Link to={ROOT_URL}>
                    <div className="Header-logo">
                        <SeekyLogo />
                    </div>
                </Link>
                <div className="Header-links">
                    <Link className="Header-link" to={SEEKY_URL_FEATURES}>Product</Link>
                    <Link className="Header-link" to={SEEKY_URL_BENEFITS}>Benefits</Link>
                    <Link className="Header-link" to={SEEKY_URL_HOW_IF_WORKS}>How&nbsp;it&nbsp;works</Link>
                    <Link className="Header-link" to={SEEKY_URL_PRICING}>Pricing</Link>
                </div>
            </div>
            { isAuthenticated() ? (
                <div className="Header-right">
                    <Link className="Header-link" onClick={logout}>
                        Log&nbsp;out
                    </Link>
                </div>
            ) : (
                <div className="Header-right">
                    <Link to="/login">
                        <Button variant="primary">Log&nbsp;in</Button>
                    </Link>
                    <Link to="/sign-up">
                        <Button variant="primary">Sign&nbsp;up</Button>
                    </Link>
                </div>
            ) }
        </div>
    );
};

const MobileHeader = ({ isSpecialBackground, handler }) => {
    const cnSpecial = isSpecialBackground ? 'Header-container-mobile__special' : '';

    return (
        <div className={`Header-container-mobile ${cnSpecial}`}>
            <div className="Header-left">
                <Link to={ROOT_URL}>
                    <div className="Header-logo">
                        <SeekyLogo />
                    </div>
                </Link>
            </div>
            <div className="Header-right">
                <Link className="Header-link" onClick={handler}>
                    <MobileMenuIcon />
                </Link>
            </div>
        </div>
    );
};

const MobileMenu = ({ isAuthenticated, logout }) => {
    return (
        <div className="Mobile-menu">
            <nav>
                <Link className="Mobile-menu-link" to={SEEKY_URL_FEATURES}>Features</Link>
                <Link className="Mobile-menu-link" to={SEEKY_URL_BENEFITS}>Benefits</Link>
                <Link className="Mobile-menu-link" to={SEEKY_URL_HOW_IF_WORKS}>How&nbsp;it&nbsp;works</Link>
                <Link className="Mobile-menu-link" to={SEEKY_URL_PRICING}>Pricing</Link>
            </nav>
            { isAuthenticated() ? (
                <Link className="Mobile-menu-link" onClick={logout}>Log&nbsp;out</Link>
            ) : (
                <>
                    <div className="Mobile-menu-buttons">
                        <div className="Mobile-menu-button">
                            <Link to="/sign-up">
                                <Button variant="primary">
                                    Sign&nbsp;up
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <Link className="Mobile-menu-link" to="/login">Log&nbsp;in</Link>
                </>
            ) }
        </div>
    );
};
