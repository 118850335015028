export const BASIC_MONTHLY_UUID = 'c95dfcc8-a63b-461d-9014-08c565103864';
export const BASIC_ANNUAL_UUID = '2faa5635-8469-47da-b445-99b91aa49c1d';

export const getBillingPlanUUID = ({ plan = 'basic', billing = 'annual' }) => {
    const key = `${plan}+${billing}`;

    switch (key) {
        case 'basic+monthly':
            return BASIC_MONTHLY_UUID;

        case 'basic+annual':
        default:
            return BASIC_ANNUAL_UUID;
    }
};
