import React, { useState, useCallback } from 'react';
import { Spin } from 'antd';

import {
    requestGetCvPdfByJobId,
    requestGetClTextfByJobId
} from '../../../../../api/v1/jobs-api';

import { ReactComponent as CvIcon } from './assets/cv.svg';
import { ReactComponent as ClIcon } from './assets/cl.svg';

import './styles.css';

export const DocsMapper = ({ auth, jobId, hasCv, hasCl }) => {
    const [ cv, setCv ] = useState(null);
    const [ isCvInProcessing, setCvInProcessing ] = useState(false);

    const [ cl, setCl ] = useState(null);
    const [ isClInProcessing, setClInProcessing ] = useState(false);

    const downloadCv = (responseCvPdf) => {
        const element = document.createElement("a");
        const file = new Blob([ responseCvPdf ], { type: 'application/pdf' });
        element.href = URL.createObjectURL(file);
        element.download = "cv.pdf";
        
        window.open(URL.createObjectURL(file));
    };

    const downloadCl = (responseClText) => {
        const element = document.createElement("a");
        const file = new Blob([ responseClText ], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "cl.txt";
        
        window.open(URL.createObjectURL(file));
    };

    const handleGenerateCv = useCallback(() => {
        setCvInProcessing(true);

        requestGetCvPdfByJobId({ auth, id: jobId })
            .then(responseCvPdf => {
                setCvInProcessing(false);
                setCv(responseCvPdf);
                downloadCv(responseCvPdf);
            })
            .catch(() => {
                setCvInProcessing(false);
            });
    }, []);

    const handleGenerateCl = useCallback(() => {
        setClInProcessing(true);

        requestGetClTextfByJobId({ auth, id: jobId })
            .then(responseText => {
                setClInProcessing(false);
                setCl(responseText);
                downloadCl(responseText);
            })
            .catch(() => {
                setClInProcessing(false);
            });
    }, []);

    return (
        <div className="docs-mapper">
            <div className="docs-mapper-item">
                { isCvInProcessing
                    ? (
                        <Spin />
                    ) : (
                        <CvIcon
                            onClick={() => { handleGenerateCv(); }}
                            style={{
                                stroke: (hasCv || cv) ? '#2ca42c' : '#bbb',
                                cursor: 'pointer'
                            }}
                            title={ hasCv ? 'CV prepared' : '' }
                        />
                    )
                }
            </div>
            <div className="docs-mapper-item">
                { isClInProcessing
                    ? (
                        <Spin />
                    ) : (
                        <ClIcon
                            onClick={() => { handleGenerateCl(); }}
                            style={{
                                stroke: (hasCl || cl) ? '#2ca42c' : '#bbb',
                                cursor: 'pointer'
                            }}
                            title={ hasCl ? 'Cover letter prepared' : '' }
                        />
                    )
                }
            </div>
        </div>
    )
};
