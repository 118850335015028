import React from 'react';
import { Progress } from 'antd';

import './styles.css';

const conicColors = {
    '0%': '#ffccc7',
    '50%': '#87d068',
    '100%': '#87d068'
};

const red = 'red';
const blue = '#0d6efd';
const green = '#1cd61c';


export const MatchingMapper = ({ matching }) => {
    if (! matching) {
        return null;
    }

    let color = 'gray';

    
    if (matching < 100) {
        color = green;
    }
    if (matching < 70) {
        color = blue;
    }
    if (matching < 30) {
        color = red;
    }

    return (
        <div className="matching-mapper">
            <Progress
                type="dashboard"
                percent={matching}
                strokeColor={
                    {
                        '0%': color,
                        '50%': color,
                        '100%': color
                    }
                }
                size={40}
            />
        </div>
    );
};
