import React, { useState, useEffect } from 'react';

import { Intro } from './steps/intro';
import { QuestionA } from './steps/question-a';
import { QuestionB } from './steps/question-b';
import { Linkedin } from './steps/linkedin';
import { Search } from './steps/search';

const SM_STATE_PREFIX = 'setup_master_2_stp_v2_';

// INIT -> WAIT -> ACTIVE -> FREEZE
export const STATE = {
    INIT: 'STATE_INIT',
    WAIT: 'STATE_WAIT',
    ACTIVE: 'STATE_ACTIVE',
    FREEZE: 'STATE_FREEZE'
};

export const STEPS = {
    INTRO: 'STEP_INTRO',
    QUESTION_A: 'STEP_QUESTION_A',
    QUESTION_B: 'STEP_QUESTION_B',
    LINKEDIN: 'STEP_LINKEDIN',
    SEARCH: 'STEP_SEARCH'
};

export const SetupMaster = ({ userID }) => {
    const [ storageData, setStorageData ] = useState(
        getSetupMasterStateFromLS(SM_STATE_PREFIX, userID) || {}
    );
    const steps = [
        STEPS.INTRO,
        STEPS.QUESTION_A,
        STEPS.QUESTION_B,
        STEPS.LINKEDIN,
        STEPS.SEARCH
    ];

    useEffect(() => {
        const data = getSetupMasterStateFromLS(SM_STATE_PREFIX, userID) || {};

        data[STEPS.INTRO] = data[STEPS.INTRO] || { state: STATE.INIT, info: {} };
        data[STEPS.QUESTION_A] = data[STEPS.QUESTION_A] || { state: STATE.INIT, info: {} };
        data[STEPS.QUESTION_B] = data[STEPS.QUESTION_B] || { state: STATE.INIT, info: {} };
        data[STEPS.LINKEDIN] = data[STEPS.LINKEDIN] || { state: STATE.INIT, info: {} };
        data[STEPS.SEARCH] = data[STEPS.SEARCH] || { state: STATE.INIT, info: {} };
   
        setStorageData(data);
        setSetupMasterStateToLS(data, SM_STATE_PREFIX, userID);
    }, [ userID ]);

    const handleChange = (currentKey, updatedState, updatedInfo) => {
        const storageData = getSetupMasterStateFromLS(SM_STATE_PREFIX, userID);

        let pleaseGiveMeNextActive = false;
        let pleaseGiveMeFirstActive = false;

        steps.forEach(key => {
            if (key === currentKey) {
                const storagedState = storageData[currentKey]?.state;

                if (storagedState === STATE.INIT || storagedState === STATE.WAIT) {
                    storageData[key] = {
                        state: updatedState,
                        info: { ...updatedInfo }
                    };
                } else if (storagedState === STATE.ACTIVE) {
                    storageData[key] = {
                        state: updatedState === STATE.FREEZE ? STATE.FREEZE : STATE.ACTIVE,
                        info: { ...updatedInfo }
                    };
                    pleaseGiveMeNextActive = updatedState === STATE.FREEZE;
                }
                // Ignoring steps: WAIT and FREEZE
            }
        });

        pleaseGiveMeFirstActive = steps
            .filter(item => storageData[item]?.state === STATE.WAIT)
            .length === steps.length;

        if (pleaseGiveMeFirstActive) {
            storageData[steps[0]].state = STATE.ACTIVE;
        } else if (pleaseGiveMeNextActive) {
            const onlyWaitedList = steps
                .filter(item => storageData[item].state === STATE.WAIT);

            if (onlyWaitedList.length > 0) {
                storageData[onlyWaitedList[0]].state = STATE.ACTIVE;
            }
        }

        setStorageData(() => storageData);
        setSetupMasterStateToLS({ ...storageData }, SM_STATE_PREFIX, userID);
    };

    return (
        <div className="setup-master">
            { storageData ? (
                <>
                    <Intro
                        stepKey={STEPS.INTRO}
                        stepState={storageData[STEPS.INTRO]?.state}
                        onChange={handleChange}
                    />
                    <QuestionA
                        stepKey={STEPS.QUESTION_A}
                        stepState={storageData[STEPS.QUESTION_A]?.state}
                        stepInfo={storageData[STEPS.QUESTION_A]?.info}
                        onChange={handleChange}
                    />
                    <QuestionB
                        stepKey={STEPS.QUESTION_B}
                        stepState={storageData[STEPS.QUESTION_B]?.state}
                        stepInfo={storageData[STEPS.QUESTION_B]?.info}
                        onChange={handleChange}
                    />
                    <Linkedin
                        stepKey={STEPS.LINKEDIN}
                        stepState={storageData[STEPS.LINKEDIN]?.state}
                        stepInfo={storageData[STEPS.LINKEDIN]?.info}
                        onChange={handleChange}
                    />
                    <Search
                        stepKey={STEPS.SEARCH}
                        stepState={storageData[STEPS.SEARCH]?.state}
                        stepInfo={storageData[STEPS.SEARCH]?.info}
                        onChange={handleChange}
                    />
                </>
            ) : null }
        </div>
    );
};

const getSetupMasterStateFromLS = (prefix, userID) => {
    if (! userID || ! prefix) {
        return null;
    }

    const key = getKeyForLS(prefix, userID);
    const raw = localStorage.getItem(key);
    const parsedRaw = JSON.parse(raw);
    const data = raw && Object.prototype.toString.call(parsedRaw) === '[object Object]'
        ? parsedRaw
        : {};

    if (! raw) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    return data;
};

const setSetupMasterStateToLS = (data, prefix, userID) => {
    if (! userID || ! prefix) {
        return false;
    }
    const key = getKeyForLS(prefix, userID);

    localStorage.setItem(key, JSON.stringify(data));
    return true;
};

const getKeyForLS = (prefix, userID) => `${prefix}_${userID}`;
