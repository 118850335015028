import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Input, Spin, Progress } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAuthHeader } from 'react-auth-kit';
import { marked } from 'marked';

import { Message, SeekyPic, handleChangeWithDelay } from '../../common';
import { STATE } from '../../setup-master';

import { matchLinkedinUrl } from './validator/validator';

import {
    fetchLinkedinProfileRequest,
    verifyLinkedinProfileRequest,
    adviceLinkedinProfileRequest
} from '../../../../../api/v1/linkedin-api';

import './styles.css';

export const Linkedin = ({ stepKey, stepState, onChange }) => {
    const authHeader = useAuthHeader();
    const auth = authHeader();

    const [ isFast, setFast ] = useState(false);
    const [ isEnabled, setEnabled ] = useState(false);
    const [ isEnabledBlock, setEnabledBlock ] = useState(false);
    const [ isEnabledQuestion, setEnabledQuestion ] = useState(false);
    const [ isEnabledQuestionLoader, setEnabledQuestionLoader ] = useState(false);
    const [ isEnabledAdvice, setEnabledAdvice ] = useState(false);
    const [ profileUrl, setProfileUrl ] = useState('');
    
    const [ warningMessage, setWarningMessage ] = useState('');

    const [ error, setError ] = useState(false);
    const [ isOkMatch, setIsOkMatch ] = useState(false);

    useEffect(() => {
        setFast(stepState === STATE.FREEZE);
        setEnabled(stepState === STATE.ACTIVE || stepState === STATE.FREEZE);
        setEnabledBlock(true);
    }, [ stepState ]);

    useEffect(() => {
        stepState === STATE.INIT &&
            handleChangeWithDelay(stepKey, STATE.WAIT, {}, onChange, 10);
    }, [ stepKey, stepState ]);

    const handleVerify = useCallback((value) => {
        if(stepState === STATE.ACTIVE) {
            if (matchLinkedinUrl(value)) {
                setError(false);
                setIsOkMatch(false);

                setProfileUrl(`https://www.linkedin.com/in/${ value.split('linkedin.com/in/')[1] }`);

                setEnabledBlock(false);
                setEnabledQuestion(true);
                setEnabledAdvice(false);
            } else {
                setError(true);
                setIsOkMatch(false);

                setProfileUrl('');

                setEnabledBlock(true);
                setEnabledQuestion(false);
                setEnabledAdvice(false);
            }
        }
    }, [ setError, stepState, setError, setIsOkMatch, setProfileUrl, setEnabledBlock, setEnabledQuestion, setEnabledAdvice ]);

    const handleQuestion = useCallback((isOk, msg = '') => {
        if (stepState === STATE.ACTIVE && isEnabledQuestion) {
            if (isOk) {
                verifyLinkedinProfileRequest({ auth })
                    .then(() => {
                        setEnabledQuestionLoader(false);
                        setEnabledAdvice(true);
                    })
                    .catch(e => {
                        setEnabledQuestionLoader(false);

                        // TODO: error notification
                        console.log('err:', e);
                    });

                setEnabledBlock(false);
                setEnabledQuestion(false);
                setEnabledQuestionLoader(true);
                setEnabledAdvice(false);
            } else {
                msg && setWarningMessage(msg);
                setEnabledBlock(true);
                setEnabledQuestion(false);
                setEnabledAdvice(false);
            }
        }
    }, [ stepState, isEnabledQuestion, setEnabledBlock, setEnabledQuestion, setEnabledAdvice, auth ]);

    const handleAdvice = useCallback(() => {
        if (stepState === STATE.ACTIVE) {
            onChange(stepKey, STATE.FREEZE, {});
        }
    }, [ stepKey, stepState ]);

    return isEnabled && (
        <div className="message">
            <div className="message-center">
                <div className="pic">
                    <SeekyPic
                        isFast={isFast}
                        delay={300}
                    />
                </div>
                <div>
                    <Block
                        isEnabled={isEnabledBlock}
                        isFast={isFast}
                        delay={300}
                        selectedOption={stepState?.selectedOption || ''}
                        onGetVerify={handleVerify}
                        error={error}
                        isOkMatch={isOkMatch}
                        state={stepState}
                        withBreaker={isEnabledQuestion || isEnabledAdvice}                        
                        warningMessage={warningMessage}
                    />
                    { isEnabledQuestion && profileUrl && (
                        <QuestionBlock
                            isEnabled={isEnabledQuestion}
                            profileUrl={profileUrl}
                            auth={auth}
                            onClick={handleQuestion}
                            state={stepState}
                        />
                    ) }
                    { isEnabledQuestionLoader && (
                        <div className="spin">
                            <Spin indicator={
                                <LoadingOutlined
                                    style={{ fontSize: 24 }}
                                    spin
                                />
                            } />
                        </div>
                    ) }
                    { isEnabledAdvice && (
                        <AdviceBlock
                            isEnabled={isEnabledAdvice}
                            auth={auth}
                            onClick={handleAdvice}
                            state={stepState}
                        />
                    ) }
                </div>
            </div>
        </div>
    );
};

const Block = ({ isEnabled, isFast, delay, selectedOption, onGetVerify, error, isOkMatch, state, withBreaker, warningMessage }) => {
    const blockRef = useRef(null);

    const [ value, setValue ] = useState(selectedOption);

    useEffect(() => { blockRef.current.scrollIntoView({ behavior: 'smooth' }); }, []);

    const handleChange = e => {
        setValue(e.target.value);
    };

    return (
        <div ref={blockRef}>
            <Message
                isFast={isFast}
                delay={delay}
                isWhiteMsg
                withBreaker={withBreaker}
                headMsg={<span>Seeky AI</span>}
                bodyMsg={
                    <Fragment>
                        <div className="text">I need to verify that your LinkedIn user profile meets standard criteria.</div>
                        <div className="text">Please enter your LinkedIn profile URL:</div>
                        <div className="text">
                            <Input
                                disabled={state !== STATE.ACTIVE || !isEnabled}
                                size="large"
                                placeholder="Linkedin URL"
                                onChange={handleChange}
                                value={value}
                            />
                        </div>
                        { error && (<div className="error-text-msg">The link is incorrect!</div>) }
                        { isOkMatch && (<div className="valid-text-msg">The link has correct form.</div>) }
                        { state == STATE.ACTIVE && isEnabled && (
                            <div className="text">
                                <Button onClick={() => onGetVerify(value)}> Verify </Button>
                            </div>
                        ) }
                        { warningMessage && (
                            <div style={{ color: 'red' }}>
                                { warningMessage }
                            </div>
                        ) }
                    </Fragment>
                }
            />
        </div>
    );
};

const QuestionBlock = ({ isEnabled, state, profileUrl, auth, onClick }) => {
    const fetchedRef = useRef(null);
    const waitRef = useRef(null);

    const [ isFetched, setFetched ] = useState(false);
    const [ linkedinProfileInfo, setLinkedinProfileInfo ] = useState(null);

    useEffect(() => {
        fetchLinkedinProfileRequest({ profileUrl, auth })
            .then(responseData => {
                setLinkedinProfileInfo({ ...responseData });
                setFetched(true);
            })
            .catch(error => {
                onClick(false, `Oops! ${error?.message || ''}`);
            })
    }, []);

    useEffect(() => { waitRef.current.scrollIntoView({ behavior: 'smooth' }); }, []);
    useEffect(() => { isFetched && fetchedRef.current.scrollIntoView({ behavior: 'smooth' }); }, [ isFetched ]);

    return isFetched ? (
        <Message
            delay={300}
            isWhiteMsg
            headMsg={<span>Seeky AI</span>}
            bodyMsg={
                <Fragment>
                    <div className="text">
                        Great, I found a profile that matches your link.
                    </div>
                    { null && (
                        <div className="text">
                            { linkedinProfileInfo?.photoUrl && (
                                <img src={linkedinProfileInfo.photoUrl} />
                            ) }
                        </div>
                    ) }
                    { true && (
                        <>
                            <div
                                className='linkedininfo1'
                                style={{
                                    background: '#dcdcdc',
                                    position: 'static',
                                    height: '75px',
                                    borderRadius: '10px 10px 0 0'
                                }}
                            >
                                <div
                                    style={{
                                        width: '64px',
                                        height: '64px',
                                        borderRadius: '50px',
                                        background: '#dcdcdc',
                                        top: '118px',
                                        position: 'fixed',
                                        left: '35px',
                                        border: 'solid white 2px',
                                        backgroundImage: `url('${linkedinProfileInfo?.photoUrl || ''}')`,
                                        backgroundSize: 'contain'
                                    }}
                                ></div>
                            </div>
                            <div
                                className='linkedininfo2'
                                style={{
                                    paddingTop: '2.5em',
                                    paddingLeft: '1em',
                                    paddingBottom: '1.5em',
                                    background: '#f2f4f7',
                                    borderRadius: '0 0 10px 10px',
                                    marginBottom: '1em'
                                }}
                            >
                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    { `${linkedinProfileInfo?.firstName} ${linkedinProfileInfo?.lastName}` }
                                </div>
                                <div style={{ fontSize: '15px' }}>
                                    { linkedinProfileInfo?.headline }
                                </div>
                                <div style={{ fontSize: '12px' }}>
                                    { linkedinProfileInfo?.location }
                                </div>
                            </div>
                        </>
                    ) }
                    <div ref={fetchedRef}>
                        <div className="text">
                            Confirm, is this your Linkedin Profile?
                        </div>
                        { state === STATE.ACTIVE && isEnabled && (
                            <div className="text">
                                <Button onClick={() => onClick(true)}> Yes </Button>
                                &nbsp;&nbsp;
                                <Button onClick={() => onClick(false)}> No </Button>
                            </div>
                        ) }
                    </div>
                </Fragment>
            }
        />
    ) : (
        <Message
            delay={300}
            isWhiteMsg
            headMsg={<span>Seeky AI</span>}
            bodyMsg={
                <Fragment>
                    <div ref={waitRef}>
                        <div style={{ marginBottom: '1em' }}>
                            <Spin indicator={
                                <LoadingOutlined
                                    style={{ fontSize: 24 }}
                                    spin
                                />
                            } />
                        </div>
                        <div className="text">
                            Fetching data. Please, wait...
                        </div>
                    </div>
                </Fragment>
            }
    />
    )
};

const AdviceBlock = ({ isEnabled, onClick, auth }) => {
    const navigate = useNavigate();
    const getRandomInt = max => Math.floor(Math.random() * max);

    const adviceRef = useRef(null);
    const [ score, setScore ] = useState('');
    const [ text, setText ] = useState([]);
    const [ isAdviceLoader, setAdviceLoader ] = useState(false);
    const [ isAdviceOk, setAdviceOk ] = useState(false);

    useEffect(() => {
        setScore((getRandomInt(2)*10) + 70);
    }, []);

    useEffect(() => {
        if (isEnabled) {
            setAdviceLoader(true);
            setAdviceOk(false);
            adviceLinkedinProfileRequest({ auth })
                .then((response) => {
                    setAdviceLoader(false);
                    setAdviceOk(true);
                    setText(
                        response ? response.split('\n') : []
                    );
                })
                .catch(() => {
                    setAdviceLoader(false);
                    setAdviceOk(false);

                    console.log('-err advice');
                    // TODO: add notification "Sorry! We can't fetch advice for your accout"
                });
        }
    }, [ isEnabled, auth ]);

    /*
    text.map((item, index) => (
        <div
            className="advice"
            key={`advice-${index}`}
            dangerouslySetInnerHTML={{__html: marked.parse(item)}}
        >
        </div>
    ))
    */

    return (
        <Message
            isFast={false}
            delay={300}
            isWhiteMsg
            headMsg={<span>Seeky AI</span>}
            bodyMsg={
                <Fragment>
                    { isAdviceLoader && (
                        <>
                            <div style={{ marginBottom: '1em' }}>
                                <Spin indicator={
                                    <LoadingOutlined
                                        style={{ fontSize: 24 }}
                                        spin
                                    />
                                } />
                            </div>
                            <div className="text">
                                Fetching advice. Please, wait...
                            </div>
                        </>
                    ) }
                    { isAdviceOk && (
                        <>
                            <div className="text" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                Great 👍 I have personalized advice for you on how to improve your LinkedIn profile.
                            </div>
                            <div className="advice-list">
                                <div className="text">
                                    {
                                        text.map((item, index) => (
                                            <div
                                                className="advice"
                                                key={`advice-${index}`}
                                                dangerouslySetInnerHTML={{__html: marked.parse(item)}}
                                            >
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </>
                    ) }
                    { !isAdviceLoader && (
                        <div ref={adviceRef} className="rating-block">
                            { isAdviceOk && (
                                <div style={{ paddingBottom: '1em' }}>
                                    <div className="tab">
                                        <div>
                                            <Progress
                                                strokeLinecap="butt"
                                                type="circle"
                                                percent={score}
                                                size='small'
                                            />
                                        </div>
                                        <div style={{ paddingLeft: '1em' }}>
                                            <div className="title">Overall rating</div>
                                            <div className="info">Your profile looks like an { score } out of 100</div>
                                        </div>
                                    </div>
                                </div>
                            ) }                        
                            <Button
                                onClick={() => { navigate('/create'); }}
                                disabled={isAdviceLoader}
                            >
                                Go to job search settings
                            </Button>
                        </div>
                    ) }
                </Fragment>
            }
        />
    );
};
