import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Typography, Col, Row, Space, Tabs, Input, Checkbox } from 'antd';
import { Button } from 'react-bootstrap';

import { ReactComponent as HomeIcon } from '../assets/home-icon.svg';

import './styles.css';

const { Title } = Typography;
const { TextArea } = Input;

export const CoverLetters = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (navigate) navigate('/cover-letters', { replace: true });
    }, [navigate]);

    return (
        <div className="cv-page">
            <Space direction="vertical" size='large' style={{ width: '100%' }}>
                <Breadcrumb
                    separator="&nbsp;&nbsp;>&nbsp;&nbsp;"
                    items={[
                        { title: <HomeIcon /> },
                        { title: 'Cover Letters' }
                    ]}
                />
                <div style={{ width: '100%'}}>
                    <Row>
                        <Col flex="auto">
                            <Title level={2} style={{ margin: '0'}}>Cover Letters Settings</Title>
                        </Col>
                    </Row>
                </div>
            </Space>
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        label: 'Test generation',
                        key: '1',
                        children: <TabGeneration />,
                    },
                    {
                        label: 'History (coming soon)',
                        key: '2',
                        children: 'Tab 2',
                        disabled: true,
                    }
                ]}
            />
        </div>
    );
};

const TabGeneration = () => {
    return (
        <Space direction="vertical" size='large' style={{ width: '100%' }}>
            <div className="info-block">
                <Space direction="horizontal" size='large' style={{ width: '100%' }}>
                    <div className="block-a">
                        <Space direction="vertical" size='middle' style={{ width: '100%' }}>
                            <TextArea rows={10} />
                            <Button disabled>Test generation</Button>
                        </Space>
                    </div>
                    <div className="block-b">
                        <Space direction="vertical" size='small' style={{ width: '100%', verticalAlign: 'top' }}>
                            <Title level={5} style={{ marginTop: '0' }}>
                                Generation Settings
                            </Title>
                            <Checkbox checked onChange={() => {}}>Analyze the job description</Checkbox>
                            <Checkbox checked onChange={() => {}}>Use only Top keywords</Checkbox>
                            <Checkbox onChange={() => {}}>Strictly follow my experience</Checkbox>
                            <Checkbox onChange={() => {}}>Use the official writing style</Checkbox>
                            <br /><br /><br />                            
                        </Space>
                    </div>
                </Space>
            </div>

        </Space>

    )
};