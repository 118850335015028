import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthHeader, useSignOut } from 'react-auth-kit';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import { Button } from 'react-bootstrap';

import { Header } from '../../components/header';
import { APP_STATE, AppContext } from '../../AppContext';

// import { ReactComponent as SeekyPageLogo } from '../../assets/seeky-page-logo.svg';
import { SIGN_UP_KEY } from '../../constants';
import { BASIC_ANNUAL_UUID } from '../../helpers/billing-plan';

import { lemonSqueezyCheckoutUrlRequest } from '../../api/v1/billing-api';

export const BillingPage = () => {
    const [ uuid ] = useState(localStorage.getItem(SIGN_UP_KEY) || BASIC_ANNUAL_UUID);
    const [ checkoutUrl, setCheckoutUrl ] = useState('');

    const { setAppState } = useContext(AppContext);
    const navigate = useNavigate();
    const authHeader = useAuthHeader();
    const signOut = useSignOut();

    useEffect(() => {
        if (authHeader && uuid) {
            lemonSqueezyCheckoutUrlRequest({ auth: authHeader(), uuid })
                .then(url => {
                    setCheckoutUrl(url);
                })
                .catch(e => {
                    console.log('err', e);
                    if (e?.response?.data?.error_code === 32) {
                        setAppState(APP_STATE.VERIFY);
                        navigate('/verify');
                    } else {
                        setAppState(APP_STATE.INDEX);
                        signOut();
                        navigate('/login');
                    }
                })
        }
    }, [ authHeader, uuid ]);

    useEffect(() => {
        if (checkoutUrl) {
            window.location.href = checkoutUrl;
        }
    }, [ checkoutUrl ])

    return (
        <div className="Page BillingPage">
            <Header />
            <div className="Page-body">
                <div className="Page-card">
                    <p>
                        <Spin indicator={
                            <LoadingOutlined
                                style={{ fontSize: 24 }}
                                spin
                            />
                        } />
                        <p style={{ paddingTop: '1em' }}>
                            Please wait...
                        </p>
                    </p>
                </div>
            </div>
        </div>
    );
};
