import axios from 'axios';

import { SEEKY_API_V1_ACTIVE_STREAK_URL } from '../../constants';

/**
 * GET: /api/v1/active_streak - Returns active streak.
 */
export const requestGetActiveStreak = async ({ auth }) => {
    return axios
        .get(`${SEEKY_API_V1_ACTIVE_STREAK_URL}`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => {
            return response?.data;
        })
        .catch(() => {
            return Promise.reject();
        });
};
