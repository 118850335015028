import React from 'react';
import { Tag } from 'antd';

const getColor = (status) => {
    switch (status) {
        case 'Active':
            return 'green';

        case 'Stopped':
            return 'red';

        case 'Archived':
        default:
            return 'default';
    }
};

export const StatusMapper = ({ status }) => {
    if (! status) {
        return null;
    }

    return (
        <div className="status-mapper">
            <Tag
                color={getColor(status)}
                icon={<>⦿{' '}</>}
            >
                { status }
            </Tag>
        </div>
    );
};
