import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Typography, Col, Row, Space } from 'antd';

import { ReactComponent as HomeIcon } from '../assets/home-icon.svg';

import './styles.css';

const { Title } = Typography;

export const Support = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (navigate) navigate('/support', { replace: true });
    }, [navigate]);

    return (
        <div className="support-page">
            <Space direction="vertical" size='large' style={{ width: '100%' }}>
                <Breadcrumb
                    separator="&nbsp;&nbsp;>&nbsp;&nbsp;"
                    items={[
                        { title: <HomeIcon /> },
                        { title: 'Application' },
                        { title: 'Support' }
                    ]}
                />
                <div style={{ width: '100%'}}>
                    <Row>
                        <Col flex="auto">
                            <Title level={2} style={{ margin: '0'}}>Support</Title>
                        </Col>
                    </Row>
                </div>
            </Space>
        </div>
    );
};
