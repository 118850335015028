import React from 'react';

import './styles.css';

const MobileMenuIcon = (props) => {
    return (
        <div className="MobileMenuIcon">
            <div className="line-top"></div>
            <div className="line-middle"></div>
            <div className="line-bottom"></div>
        </div>
    );
};

export { MobileMenuIcon };
