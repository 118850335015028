import axios from 'axios';

import {
    SEEKY_API_V1_BILLING_LS_CALLBACK_URL,
    SEEKY_API_V1_BILLING_LS_CHECKOUT_URL,
    SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_CANCEL_URL,
    SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_LAST_URL,
    SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_RESUME_URL,
    SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_UPDATE_URL
} from '../../constants';

// Handle LemonSqueezy callback
export const lemonSqueezyCallbackRequest = async ({ xsignature }) => {
    return axios
        .post(`${SEEKY_API_V1_BILLING_LS_CALLBACK_URL}`,
            { headers: {
                'accept': 'application/json',
                'x-signature': `${ xsignature }`
            } }
        )
        .then(response => response?.data || '')
        .catch(() => Promise.reject());
};

// Returns LemonSqueezy checkout url
export const lemonSqueezyCheckoutUrlRequest = async ({ auth, uuid }) => {
    return axios
        .get(`${SEEKY_API_V1_BILLING_LS_CHECKOUT_URL}/${uuid}`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => response?.data || '')
        .catch(e => Promise.reject(e));
};

// Cancel subscription
export const lemonSqueezyCancelSubscriptionRequest = async ({ auth }) => {
    return axios
        .delete(`${SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_CANCEL_URL}`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => response?.data || '')
        .catch(() => Promise.reject());
};

// Returns information about user's last subscription
export const lemonSqueezyLastSubscriptionRequest = async ({ auth }) => {
    return axios
        .get(`${SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_LAST_URL}`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => response?.data || '')
        .catch(() => Promise.reject());
};

// Resume subscription
export const lemonSqueezyResumeSubscriptionRequest = async ({ auth }) => {
    return axios
        .patch(`${SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_RESUME_URL}`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => response?.data || '')
        .catch(() => Promise.reject());
};

// Update payment method
export const lemonSqueezyUpdateSubscriptionRequest = async ({ auth }) => {
    return axios
        .patch(`${SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_UPDATE_URL}`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => response?.data || '')
        .catch(() => Promise.reject());
};
