export const validateUsername = value => run(value, [ checkEmpty ]);
export const validateEmail = value => run(value, [ checkEmpty, checkEmail ]);
export const validatePassword = value => run(value, [ checkEmpty, checkPassword ]);

const run = (value, validators) => {
    const results = validators
        .map(fn => fn(value || ''))
        .filter(v => v);

    return results.length > 0
        ? results[0]
        : '';
};
const checkEmpty = value => (!value || typeof value !== 'string')  ? 'This field must not be empty' : '';
const checkEmail = value => {
    return matchEmail(value) ? '' : 'Email address required';
};
const checkPassword = value => {
    return matchPassword(value) ? '' : 'Your password must be contains at least 8 characters and also contains: numbers, lowercase (a-z) and uppercase (A-Z) characters';
};

export const matchEmail = value => {
    return String(value)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export const matchPassword = value => {
    return String(value)
        .match(
            /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9a-zA-Z!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{8,}/
        );
};
