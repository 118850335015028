/* Application configs */
export const ROOT_URL = "/"; // "https://www.seeky.ai"
export const FOOTER_CURRENT_YEAR_VALUE = "2024";
export const MOBILE_WIDTH_VALUE = 700;
export const LINKEDIN_PARTNER_ID = "5880204";

/* API's */
export const SEEKY_API_V1 = "/api/v1";

export const SEEKY_API_V1_AUTH_LOGIN_URL = `${SEEKY_API_V1}/auth/jwt/login`;
export const SEEKY_API_V1_AUTH_LOGOUT_URL = `${SEEKY_API_V1}/auth/jwt/logout`;
export const SEEKY_API_V1_AUTH_REQUEST_VERIFY_TOKEN_URL = `${SEEKY_API_V1}/auth/request-verify-token`;
export const SEEKY_API_V1_AUTH_VERIFY_URL = `${SEEKY_API_V1}/auth/verify`;
export const SEEKY_API_V1_AUTH_REGISTER_URL = `${SEEKY_API_V1}/auth/register`;

export const SEEKY_API_V1_SEARCH_FILTERS_CREATE_URL = `${SEEKY_API_V1}/search_filters`;

export const SEEKY_API_V1_LINKEDIN_FETCH_URL = `${SEEKY_API_V1}/linkedin/fetch`;
export const SEEKY_API_V1_LINKEDIN_VERIFY_URL = `${SEEKY_API_V1}/linkedin/verify`;
export const SEEKY_API_V1_LINKEDIN_ADVICE_URL = `${SEEKY_API_V1}/linkedin/advice`;

export const SEEKY_API_V1_JOBS_TITLES_URL = `${SEEKY_API_V1}/jobs/titles`;
export const SEEKY_API_V1_JOBS_URL = `/api/v1/jobs`;

export const SEEKY_API_V1_ACTIVE_STREAK_URL = `/api/v1/active_streak`;

export const SEEKY_API_V1_LOCATIONS_COUNTRIES_PATH = 'countries';
export const SEEKY_API_V1_LOCATIONS_REGIONS_PATH = 'regions';
export const SEEKY_API_V1_LOCATIONS_CITY_PATH = 'city';

export const SEEKY_API_V1_USERS_ME_URL = `${SEEKY_API_V1}/users/me`;

export const SEEKY_API_V1_BILLING_LS = `${SEEKY_API_V1}/billing/lemonsqueezy`;
export const SEEKY_API_V1_BILLING_LS_CALLBACK_URL = `${SEEKY_API_V1_BILLING_LS}/callback`;
export const SEEKY_API_V1_BILLING_LS_CHECKOUT_URL = `${SEEKY_API_V1_BILLING_LS}/checkout`;
export const SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_CANCEL_URL = `${SEEKY_API_V1_BILLING_LS}/subscription/cancel`;
export const SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_LAST_URL = `${SEEKY_API_V1_BILLING_LS}/subscription/last`;
export const SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_RESUME_URL = `${SEEKY_API_V1_BILLING_LS}/subscription/resume`;
export const SEEKY_API_V1_BILLING_LS_SUBSCRIPTION_UPDATE_URL = `${SEEKY_API_V1_BILLING_LS}/subscription/update_payment_method`;

/* Landing Page's configs */
export const SEEKY_URL_FEATURES = "https://www.seeky.ai/#Features";
export const SEEKY_URL_BENEFITS = "https://www.seeky.ai/#Benefits";
export const SEEKY_URL_HOW_IF_WORKS = "https://www.seeky.ai/#How-it-works";
export const SEEKY_URL_PRICING = "https://www.seeky.ai/#Pricing";

/* Local Storage Keys */

export const SIGN_UP_KEY = 'sign_up_key';
