import React from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { Header } from '../../components/header';
import { ReactComponent as SearchImage } from '../http404/assets/search.svg';

import '../common/styles.css';
import './styles.css';

const Http404Page = () => {
    const navigate = useNavigate();

    return (
        <div className="Http404Page">
            <Header />
            <div className="Page-body">
                <div className="Page-card">
                    <div className="Page-logo">
                        <SearchImage />
                    </div>
                    <h1>Page not found</h1>
                    <p>
                        The page you are looking for doesn't exist.<br />
                        Here are some helpful links:
                    </p>
                    <div className="buttons">
                        <Button
                            className="link"
                            variant="link"
                            onClick={() => { navigate(-1); }}
                        >
                            ←&nbsp;Go&nbsp;back
                        </Button>
                        <Link to="/">
                            <Button
                                className="button"
                                variant="primary"
                            >
                                Go&nbsp;home
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { Http404Page };
