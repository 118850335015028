import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';
import { AuthProvider } from 'react-auth-kit';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const Root = () => {
    useEffect(() => {
        WebFont.load({ google: { families: ['Inter'] } });
    }, []);

    return (
        <>
            <AuthProvider 
                authName={'_auth'}
                authType={'cookie'}
                cookieDomain={window.location.hostname}
                cookieSecure={window.location.protocol === 'https:'}
            >
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </AuthProvider>
        </>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Root />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
