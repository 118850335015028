import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ActionIcon } from './assets/action.svg';

import './styles.css';

export const ActionsMapper = ({ link }) => {
    return (
        <div className="actions-mapper">
            { link && (
                <Link to={link} target='_blank'>
                    <ActionIcon />
                </Link>
            ) }
        </div>
    )
};