import React from 'react';

import { MessageWrapper } from '../common';

export const Buttons = ({ isFast, delay, bodyMsg }) => {
    return (
        <MessageWrapper
            delay={isFast ? 0 : delay}
            body={(state) => (
                <div className={
                    isFast
                        ? 'message-item-transparent-fast'
                        : `message-item-transparent message-item--${state}`
                }>
                    { bodyMsg }
                </div>
            )}
        />
    );
};
