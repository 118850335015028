import React, { Fragment, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthHeader, useIsAuthenticated, useSignOut } from 'react-auth-kit';

import { Button } from 'react-bootstrap';
import { Select, Switch, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { AppContext } from '../../AppContext';

import { getJobsTitlesRequest } from '../../api/v1/jobs-api';
import {
    getCountriesRequest,
    getRegionsRequest,
    getCitiesRequest
} from '../../api/v1/locations-api';
import {
    requestCreateNewSearch,
    requestGetListOfSearchFilters
} from '../../api/v1/search-filters-api';

import { Header } from '../../components/header';
import { Message } from '../welcome2/setup-master/common';

import '../common/styles.css';
import '../welcome2/styles.css';

export const CreatePage = () => {
    const [ isProgressDone, setProgressDone ] = useState(false);
    const { appUser, appToken } = useContext(AppContext);
    const authHeader = useAuthHeader();
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const [ searchingStarted, setSearchingStarted ] = useState(false);
    const [ isError, setError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ isFirstSearching, setFirstSearching ] = useState(true);

    const handleSearchClick = (data) => {
        const auth =  authHeader();

        setError(false);
        requestCreateNewSearch({ data, auth })
            .then(() => {
                return requestGetListOfSearchFilters({ auth });
            })
            .then(data => {
                if (data?.length > 1) { // old searchings + new one searching
                    setFirstSearching(false);
                } else {
                    setFirstSearching(true);
                }
                setSearchingStarted(true);
            })
            .catch(error => {
                setError(true);
                setErrorMessage(error?.message || 'Error');
                setSearchingStarted(false);
            });
    };

    const handleDone = () => {
        setProgressDone(true);
        navigate('/dashboard');
    };

    return (
        <div className="Page WelcomePage">
            <Header isSpecialBackground />
            <br />
            { appUser?.id && authHeader() && isAuthenticated() ? (
                <div className="message step-search" style={{ maxWidth: '900px' }}>
                    <div className="message-center">
                    {
                        searchingStarted ? (
                            <>
                                <br /><br />
                                Searching...
                                <br /><br />
                                <Spin indicator={
                                    <LoadingOutlined
                                        style={{ fontSize: 48 }}
                                        spin
                                    />
                                } />
                                { !isProgressDone && isFirstSearching && (
                                    <ProgressForFirstSearching
                                        disabled={!isFirstSearching}
                                        handleDone={handleDone}
                                    />
                                ) }
                                { !isProgressDone && !isFirstSearching && (
                                    <ProgressFast
                                        disabled={isFirstSearching}
                                        handleDone={handleDone}
                                    />
                                ) }
                            </>
                        ) : (
                            <div>
                                <Block
                                    auth={authHeader()}
                                    handleSearchClick={handleSearchClick}
                                    isError={isError}
                                    errorMessage={errorMessage}
                                />
                            </div>
                        )
                    }
                    </div>
                </div>
            ) : (
                <div>Wait...</div>
            )}
        </div>
    );
};

const Block = ({ auth, handleSearchClick, isError, errorMessage }) => {
    const delay = 300;
    const blockRef = useRef(null);
    // const { appToken } = useContext(AppContext);

    const appToken = { token: auth };

    const [ isOk, setOk ] = useState(false);

    const [ job, setJob ] = useState('');
    const [ country, setCountry ] = useState('');
    const [ region, setRegion ] = useState('');
    const [ city, setCity ] = useState('');

    const [ isEnabledRegion, setEnabledRegion ] = useState(false);
    const [ isEnabledCity, setEnabledCity ] = useState(false);

    useEffect(() => { blockRef.current.scrollIntoView({ behavior: 'smooth' }); }, []);
    useEffect(() => { setEnabledRegion(!! country); }, [ country ]);
    useEffect(() => { !isEnabledCity && setCity(''); }, [ isEnabledCity ]);

    useEffect(() => {
        if ((job && country && region && !isEnabledCity) ||
            (job && country && region && isEnabledCity && city)) {
            setOk(true);
        } else {
            setOk(false);
        }
    }, [ job, country, region, city, isEnabledCity ]);

    const handleJob = useCallback(v => {
        setJob(v);
    }, [ setJob ]);

    const handleCountry = useCallback(v => {
        setCountry(v);
        setRegion('');
        setCity('');
        setEnabledCity(false);
    }, [ setCountry ]);

    const handleRegion = useCallback(v => {
        setRegion(v);
        setCity('');
        setEnabledCity(false);
    }, [ setRegion ]);

    const handleSwitch = useCallback(v => {
        setEnabledCity(v)
    }, [ setEnabledCity ]);

    const handleCity = useCallback(v => {
        setCity(v);
    }, [ setCity ]);

    return (
        <div ref={blockRef}>
            <Message
                delay={delay}
                isWhiteMsg
                headMsg={<></>}
                bodyMsg={
                    <Fragment>
                        <div className="text">
                            Now, let's set up the search criteria for suitable vacancies.
                        </div>
                        <SelectJob
                            appToken={appToken}
                            value={job}
                            onSelected={handleJob}
                        />
                        <SelectCountry
                            appToken={appToken}
                            value={country}
                            onSelected={handleCountry}
                        />
                        <SelectRegion
                            appToken={appToken}
                            value={region}
                            isEnabled={isEnabledRegion}
                            countryName={country}
                            onSelected={handleRegion}
                        />
                        <div className="search-item">
                            <Switch
                                disabled={! isEnabledRegion}
                                size="small"
                                onChange={handleSwitch}
                            />
                            <span className="switch-label">I need to choose a city</span>
                        </div>
                        <div className="text">We don’t recommend choosing a city as it gives fewer search results.</div>
                        <SelectCity
                            appToken={appToken}
                            value={city}
                            isEnabled={isEnabledCity}
                            countryName={country}
                            regionName={region}
                            onSelected={handleCity}
                        />
                        <div className="search-btn-border">
                            <Button
                                disabled={! isOk}
                                className="search-btn"
                                onClick={() => {
                                    console.log('page: /create - create search');
                                    handleSearchClick({ job, location: { country, region, city }});
                                }}
                            >
                                Create Search
                            </Button>
                        </div>
                        { isError && (
                            <div style={{ color: 'red' }}>
                                { errorMessage }
                            </div>
                        )}
                    </Fragment>
                }
            />
        </div>
    );
};

const SelectJob = ({ appToken, value, onSelected }) => {
    const [ jobList, setJobList ] = useState([]);
    const [ current, setCurrent ] = useState(value || '');
    const [ selectedValue, setSelectedValue ] = useState(value || '');

    useEffect(() => {
        if (value !== selectedValue) { setCurrent(value); setSelectedValue(value); }
    }, [ value ]);     

    useEffect(() => { selectedValue && onSelected && onSelected(selectedValue); }, [ selectedValue ]);
    useEffect(() => {
        appToken?.token &&
            getJobsTitlesRequest({ filterByName: current, auth: `${appToken.token}` })
                .then(responseData => {
                    const list = responseData
                        .map(item => {
                            return { value: item, label: item };
                        });

                    setJobList(list);
                })
                .catch(() => {});
    }, [ appToken, setJobList, current ]);

    return (
        <div className="search-item">
            <div className="label">Job Title</div>
            <Select
                value={selectedValue}
                style={{ width: '100%' }}
                showSearch
                placeholder="Select job title"
                optionFilterProp="children"
                onChange={(v) => { setCurrent(v); setSelectedValue(v); }}
                onSearch={(v) => { setCurrent(v); }}
                options={[ ...jobList ]}
                filterOption={() => true}
            />
        </div>
    );
};

const SelectCountry = ({ appToken, value, onSelected }) => {
    const [ countryList, setCountryList ] = useState([]);
    const [ current, setCurrent ] = useState(value || '');
    const [ selectedValue, setSelectedValue ] = useState(value || '');

    useEffect(() => {
        if (value !== selectedValue) { setCurrent(value); setSelectedValue(value); }
    }, [ value ]);

    useEffect(() => { selectedValue && onSelected && onSelected(selectedValue); }, [ selectedValue ]);
    useEffect(() => {
        appToken?.token &&
            getCountriesRequest({ filterByName: current, auth: `${appToken.token}` })
                .then(responseData => {
                    const list = responseData
                        .map(item => {
                            return { value: item, label: item };
                        });

                    setCountryList(list);
                })
                .catch(() => {});
    }, [ appToken, setCountryList, current ]);

    return (
        <div className="search-item">
            <div className="label">Country</div>
            <Select
                style={{ width: '100%' }}
                value={selectedValue}
                showSearch
                placeholder="Select your country"
                optionFilterProp="children"
                onChange={(v) => { setCurrent(v); setSelectedValue(v); }}
                onSearch={(v) => { setCurrent(v); }}
                options={countryList}
                filterOption={() => true}
            />
        </div>
    );
};

const SelectRegion = ({ appToken, value, isEnabled, countryName, onSelected }) => {
    const [ list, setList ] = useState([]);
    const [ current, setCurrent ] = useState(value || '');
    const [ selectedValue, setSelectedValue ] = useState(value || '');

    useEffect(() => {
        if (value !== selectedValue) { setCurrent(value); setSelectedValue(value); }
    }, [ value ]);

    useEffect(() => { selectedValue && onSelected && onSelected(selectedValue); }, [ selectedValue ]);
    useEffect(() => {
        appToken?.token &&
            getRegionsRequest({
                auth: `${appToken.token}`,
                countryName: countryName,
                filterByName: current
            })
                .then(responseData => {
                    setList(
                        responseData
                            .map(item => { return { value: item, label: item }; })
                    );
                })
                .catch(() => {});
    }, [ appToken, setList, countryName, current ]);

    return (
        <div className="search-item">
            <div className="label">
                { countryName === 'United States' ? 'State' : 'Region' }
            </div>
            <Select
                disabled={! isEnabled}
                value={selectedValue}
                style={{ width: '100%' }}
                showSearch
                placeholder="Select your region"
                optionFilterProp="children"
                onChange={(v) => { setCurrent(v); setSelectedValue(v); }}
                onSearch={(v) => { setCurrent(v); }}
                options={list}
                filterOption={() => true}
            />
        </div>
    );
};

const SelectCity = ({ appToken, value, isEnabled, countryName, regionName, onSelected }) => {
    const [ list, setList ] = useState([]);
    const [ current, setCurrent ] = useState(value || '');
    const [ selectedValue, setSelectedValue ] = useState(value || '');

    useEffect(() => {
        if (value !== selectedValue) { setCurrent(value); setSelectedValue(value); }
    }, [ value ]);

    useEffect(() => { selectedValue && onSelected && onSelected(selectedValue); }, [ selectedValue ]);
    useEffect(() => {
        appToken?.token &&
            getCitiesRequest({
                auth: `${appToken.token}`,
                countryName: countryName,
                regionName: regionName,
                filterByName: current
            })
                .then(responseData => {
                    setList(
                        responseData
                            .map(item => { return { value: item, label: item }; })
                    );
                })
                .catch(() => {});
    }, [ appToken, setList, countryName, current ]);

    return isEnabled && (
        <div className="search-item">
            <div className="label">City</div>
            <Select
                disabled={! isEnabled}
                value={selectedValue}
                style={{ width: '100%' }}
                showSearch
                placeholder="Select your city"
                optionFilterProp="children"
                onChange={(v) => { setCurrent(v); setSelectedValue(v); }}
                onSearch={(v) => { setCurrent(v); }}
                options={list}
                filterOption={() => true}
            />
        </div>
    );
};

const progressList = [    
    'Processing your data',
    'Syncing information with the server',
    'Optimizing system performance',
    'Loading necessary resources',
    'Updating the database',
    'Checking for security standards',
    'Analyzing data',
    'Organizing the information received',
    'Starting processing operations',
    'Compiling needed modules',
    'Validating entered data',
    'Connecting to cloud services',
    'Activating machine learning algorithms',
    'Caching data for faster access',
    'Improving user interface interaction',
    'Integrating new features',
    'Ensuring data encryption',
    'Scaling the system',
    'Speeding up loading',
    'Updating user settings',
    'Saving work progress',
    'Preparing performance reports',
    'Allocating resources for processing',
    'Adapting the system for current tasks',
    'Backing up data',
    'Analyzing user interaction trends',
    'Setting security parameters',
    'Implementing optimization strategies',
    'Connecting additional services',
    'Reconfiguring system architecture'
];

const ProgressForFirstSearching = ({ disabled, handleDone }) => {
    const [ position, setPosition ] = useState(0);

    useEffect(() => {
        setPosition(1);
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (progressList.length > position) {
                setPosition(position + 1);
            } else {
                handleDone();
            }
        }, 1500);
      
          // Cleanup function to clear the timeout if the component unmounts
          return () => clearTimeout(timeoutId);
    }, [ position ]);

    return !disabled ? (
        <div>
            <br />
            { progressList[ position ] }
        </div>
    ) : null;
};

const ProgressFast = ({ disabled, handleDone }) => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleDone();
        }, 5000);
      
          // Cleanup function to clear the timeout if the component unmounts
          return () => clearTimeout(timeoutId);
    }, []);

    return !disabled ? (
        <div>
            <br />
            A new search has been created and new options will appear in the list of vacancies soon...
        </div>
    ) : null;
};
