import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useAuthHeader, useSignOut, useIsAuthenticated } from 'react-auth-kit';
import { Button } from 'react-bootstrap';

import { AppContext, APP_STATE } from '../../AppContext';

import { Header } from '../../components/header';
import { ReactComponent as PostalImage } from '../verify/assets/postal.svg';
import { ReactComponent as ProblemImage } from '../verify/assets/error.svg';

import { getCurrentUserInfoRequest } from '../../api/v1/users-api';

import {
    verifyTokenFromEmailRequest,
    sendTokenToEmailRequest
} from '../../api/v1/auth-api';

import '../common/styles.css';
import './styles.css';

export const VERIFICATION_STATE = {
    WAIT: 1,
    OK: 2,
    FAIL: 3
};

/* const verifyTokenRequest = async (token) => {
    return await verifyTokenFromEmailRequest(token);
}; */

export const VerifyPage = () => {
    const { appState, setAppState, appUser, setAppUser } = useContext(AppContext);
    const [ state, setState ] = useState(VERIFICATION_STATE.WAIT);

    const navigate = useNavigate();
    const authHeader = useAuthHeader();
    const isAuthenticated = useIsAuthenticated();
    const signOut = useSignOut();
    const [ searchParams ] = useSearchParams();

    const token = searchParams.get('token');

    const handleActivatedClick = () => {
        setAppState(APP_STATE.WELCOME);
        navigate('/welcome');
    };
    const handleResetClick = () => {
        appUser.email && sendTokenToEmailRequest(appUser.email);
        setState(VERIFICATION_STATE.WAIT);
    };
    const handleGoNextStepClick = () => {
        navigate('/welcome');
        setAppState(APP_STATE.WELCOME);
    };

    const updateAppUser = useCallback(() => {
        getCurrentUserInfoRequest({ auth: authHeader() })
            .then(responseData => {
                if (responseData) {
                    setAppUser({
                        id: responseData?.id,
                        email: responseData?.email,
                        isActive: responseData?.is_active,
                        isVerified: responseData?.is_verified,
                        firstName: responseData?.firstName,
                        lastName: responseData?.lastName,
                        hasSubscription: responseData?.subscription?.status
                            ? responseData?.subscription?.status !== 'never_had'
                            : false,
                        hasVerifiedLinkedin: responseData?.linkedin_profile_is_verified
                    });
                } else {
                    signOut();
                }
            });
    }, [ authHeader, setAppUser, signOut ]);

    useEffect(() => {
        if (state === VERIFICATION_STATE.WAIT && token) {
            verifyTokenFromEmailRequest(token)
                .then(result => {
                    result && setState(VERIFICATION_STATE.OK);
                })
                .catch(() => {
                    setState(VERIFICATION_STATE.FAIL)
                });
        }

        if (! token) {
            if (isAuthenticated()) {
                if (appUser.id) {
                    if (appUser.hasSubscription) {
                        if (appUser.isVerified) {
                            setAppState(APP_STATE.WELCOME);
                            navigate('/welcome');
                        } else {
                            setAppState(APP_STATE.VERIFY);
                        }
                    } else {
                        //setAppState(APP_STATE.BILLING);
                        //navigate('/billing');
                        console.log('verify 1');
                    }                    
                } else {
                    updateAppUser();
                }
            } else {
                setAppState(APP_STATE.LOGIN);
                navigate('/login');
            }
        }
    }, [ appState, appUser, setAppState, state, setState, updateAppUser, isAuthenticated, navigate, token ]);

    return (
        <div className="Page VerifyPage">
            <Header />
            {isAuthenticated() && state === VERIFICATION_STATE.WAIT && appUser.id && (
                <ReadyToSendEmail
                    email={appUser.email}
                    onResetClick={handleResetClick}
                    onGoNextStepClick={handleGoNextStepClick}
                />
            )}
            {state === VERIFICATION_STATE.OK && (
                <AccountActivated
                    onClick={handleActivatedClick}
                />
            )}
            {state === VERIFICATION_STATE.FAIL && (
                <VerifyWithProblems
                    email={appUser.email}
                    onResetClick={handleResetClick}
                />
            )}
        </div>
    );
};

const ReadyToSendEmail = ({ email, onResetClick, onGoNextStepClick }) => {
    return (
        <div className="Page-body">
            <div className="Page-card">
                <div className="Page-logo">
                    <PostalImage />
                </div>
                <h1>Verify your email</h1>
                <p>
                    You need to verify your email to continue.
                    If&nbsp;you don't see it, you may need to check your spam folder.
                </p>
                {email && (
                    <Link className="Verify-link" onClick={onResetClick}>
                        Send email again
                    </Link>
                )}
                <br />
                or
                <br />
                <p style={{ marginTop: '0.5em' }}>
                    <Button onClick={onGoNextStepClick}>
                        Ok, I will do it later
                    </Button>
                </p>
            </div>
        </div>
    );
};

const AccountActivated = ({ onClick }) => {
    return (
        <div className="Page-body">
            <div className="Page-card">
                <div className="Page-logo">
                    <PostalImage />
                </div>
                <h1>Account activated</h1>
                <p>
                    Your account has been activated successfully.
                </p>
                <Button className="form-btn" onClick={onClick}>
                    Get started
                </Button>           
            </div>
        </div>
    );
};

const VerifyWithProblems = ({ email, onResetClick }) => {
    return (
        <div className="Page-body">
            <div className="Page-card">
                <div className="Page-logo Verify-problem-logo">
                    <ProblemImage />
                </div>
                <h1>Verification problems</h1>
                <p>
                    We couldn't verify your account.
                </p>
                <p>
                    Please try again, or contact&nbsp;us if you continue to have problems.
                </p>
                {email && (
                    <Link className="Verify-link" onClick={onResetClick}>
                        Send email again
                    </Link>
                )}
            </div>
        </div>
    );
};
