import React, { useEffect, useCallback, useContext } from 'react';

// import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuthHeader, useSignOut, useIsAuthenticated } from 'react-auth-kit';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { Header } from '../../components/header';
import { AppContext, APP_STATE } from '../../AppContext';
import { ReactComponent as SeekyPageLogo } from '../../assets/seeky-page-logo.svg';

import { getCurrentUserInfoRequest } from '../../api/v1/users-api';
// import { lemonSqueezyCallbackRequest } from '../../api/v1/billing-api';
import { requestGetJobsList } from '../../api/v1/jobs-api';

export const BillingCallbackPage = () => {
    const { appState, setAppState, appToken, appUser, setAppUser } = useContext(AppContext);
    
    const authHeader = useAuthHeader();
    const navigate = useNavigate();
    const signOut = useSignOut();
    const isAuthenticated = useIsAuthenticated();

    const updateAppUser = useCallback(() => {
        getCurrentUserInfoRequest({ auth: authHeader() })
            .then(responseData => {
                if (responseData) {
                    setAppUser({
                        id: responseData?.id,
                        email: responseData?.email,
                        isActive: responseData?.is_active,
                        isVerified: responseData?.is_verified,
                        firstName: responseData?.firstName,
                        lastName: responseData?.lastName,
                        hasSubscription: responseData?.subscription?.status
                            ? responseData?.subscription?.status !== 'never_had'
                            : false,
                        hasVerifiedLinkedin: responseData?.linkedin_profile_is_verified
                    });
                    console.log('has subsription:', responseData?.subscription?.status);
                } else {
                    signOut();
                }
            });
    }, [ authHeader, setAppUser, signOut ]);

    useEffect(() => {
        if (appState === APP_STATE.INDEX) {
            if (isAuthenticated()) {
                if (! appUser.id) {
                    updateAppUser();
                } else {
                    /* lemonSqueezyCallbackRequest({ xsignature: '-' })
                        .then(r => {
                            console.log('ok:', r);
                        })
                        .catch(e => {
                            console.log('err:', e);
                        });*/

                    // TODO:
                    // - check verify
                    // - check linkedin in

                    requestGetJobsList({ auth: authHeader() })
                        .then(responseData => {
                            if (responseData?.items?.length === 0) {
                                setAppState(APP_STATE.VERIFY);
                                navigate('/verify');
                            } else {
                                setAppState(APP_STATE.MAIN);
                                navigate('/dashboard');
                            }
                        })

                }
            } else {
                setAppState(APP_STATE.LOGIN);
            }
        } else if (appState === APP_STATE.LOGIN_OK) {
            if (! appUser.id) {
                updateAppUser();
            } else {
                setAppState(APP_STATE.INDEX);
            }
        }
    }, [ appState, appToken, appUser, setAppState, setAppUser, authHeader, isAuthenticated, navigate, updateAppUser ]);

    return (
        <div className="Page BillingPage">
            <Header />
            <div className="Page-body">
                <div className="Page-card">
                    <div className="Page-logo">
                        <SeekyPageLogo />
                    </div>
                    <h1>Billing processing</h1>
                    <Spin indicator={
                        <LoadingOutlined
                            style={{ fontSize: 24 }}
                            spin
                        />
                    } />
                    <p style={{ paddingTop: '1em' }}>
                        Please wait...
                    </p>
                </div>
            </div>
        </div>
    );
};
