import React, { useState, useEffect, useCallback } from 'react';

import { Message, Buttons, SeekyPic, handleChangeWithDelay } from '../../common';
import { STATE } from '../../setup-master';

export const QuestionA = ({ stepKey, stepState, stepInfo, onChange }) => {
    const [ isFast, setFast ] = useState(false);
    const [ isEnabled, setEnabled ] = useState(false);
    const [ selectedOption, setSelectedOption ] = useState('');

    useEffect(() => {
        setFast(stepState === STATE.FREEZE);
        setEnabled(stepState === STATE.ACTIVE || stepState === STATE.FREEZE);
    }, [ stepState ]);

    useEffect(() => {
        stepInfo?.selectedOption &&
            setSelectedOption(stepInfo.selectedOption);
    }, [ stepInfo ]);

    useEffect(() => {
        stepState === STATE.INIT &&
            handleChangeWithDelay(stepKey, STATE.WAIT, {}, onChange, 10);
    }, [ stepKey, stepState ]);

    const handleAnswer = useCallback((selected) => {
        setSelectedOption(selected);
        onChange(stepKey, STATE.FREEZE, { selectedOption: selected });
    }, [ onChange, stepKey ]);

    return isEnabled && (
        <div className="message">
            <div className="message-center">
                <div className="pic">
                    <SeekyPic
                        isFast={isFast}
                        delay={300}
                    />
                </div>
                <div>
                    <HelloText
                        isFast={isFast}
                        delay={300}
                        withBreaker
                    />
                    <QuestionText
                        isFast={isFast}
                        delay={1500}
                    />
                    <ButtonsBlock 
                        isFast={isFast}
                        delay={2500}
                        selectedOption={selectedOption}
                        onGetAnswer={handleAnswer}
                    />
                </div>
            </div>
        </div>
    );
};

const HelloText = ({ isFast, delay, withBreaker }) => {
    return (
        <Message
            isFast={isFast}
            delay={delay}
            headMsg={<span>Seeky AI</span>}
            bodyMsg={<div className="text">Hello, I'm Seeky! 👋</div>}
            withBreaker={withBreaker}
        />
    );
};

const QuestionText = ({ isFast, delay }) => {
    return (
        <Message
            isFast={isFast}
            delay={delay}
            headMsg={<span>Seeky AI</span>}
            bodyMsg={<div className="text">How did you find out about the product?</div>}
        />
    );
};

const ButtonsBlock = ({ isFast, delay, selectedOption, onGetAnswer }) => {
    const [ isBlocked, setBlocked ] = useState(!! selectedOption);
    const [ selected, setSelected ] = useState(selectedOption);

    const options = [
        "Product Hunt",
        "Advertisement",
        "Search Engines",
        "Social Media",
        "Articles, blogs",
        "Friends, recommendations"
    ];

    const handleClick = (item) => {
        if (! isBlocked) {
            setBlocked(true);
            setSelected(item);
        }
    };

    useEffect(() => {
        if (isBlocked && onGetAnswer && typeof onGetAnswer === 'function') {
            onGetAnswer(selected);
        }
    }, [ isBlocked ]);

    return (
        <Buttons
            isFast={isFast}
            delay={delay}
            bodyMsg={(
                options?.map((item, index) => {
                    return (
                        <div
                            className={
                                `question-btn question-btn-${
                                    item === selected
                                        ? 'selected'
                                        : isBlocked
                                            ? 'passive'
                                            : 'active'
                                }`
                            }
                            key={`question-a-btn-${index}`}
                            onClick={() => handleClick(item)}
                        >
                            { item }
                        </div>
                    );
                })
            )}
        />
    );
};
