import React, { useEffect } from 'react';

import { MessageWrapper } from '../common';

import picFounder from '../../assets/founder.png';

export const Intro = ({ fast, onStepNext }) => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            console.log('click!');
            onStepNext();
        }, 100);

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <div className="message">
            <div className="message-left">
                <FounderPic
                    fast={fast}
                    delay={500}
                />
            </div>
            <div className="message-right">
                <Text 
                    fast={fast}
                    delay={500}
                />
            </div>            
        </div>
    );
};

const FounderPic = ({ fast, delay}) => {
    return (
        <MessageWrapper
            delay={fast ? 0 : delay}
            body={(state) => (
                <img
                    className={fast ? 'img-fast ' : `img img--${state}`}
                    src={picFounder}
                    alt="Antony Balakirev"
                />
            )}
        />
    );
};

const Text = ({ fast, delay }) => {
    return (
        <MessageWrapper
            delay={fast ? 0 : delay}
            body={(state) => (
                <div className={fast ? 'message-item-fast' : `message-item message-item--${state}`}>
                    <div className="board-item-spec">
                        <span>Antony Balakirev</span>
                        <span>Founder</span>
                    </div>
                    <div className="text">Welcome to your personal account of the Seeky AI.</div>
                    <div className="text">Thank you for joining us, we will be happy to help you with your dream job search tasks.</div>
                    <div className="text">Thanks, the team Seeky AI.</div>
                </div>
            )}
        />
    );
};
