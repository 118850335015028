import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthHeader, useSignOut } from 'react-auth-kit';
import { Layout, Flex, Menu, Divider, Avatar } from 'antd';
import { UserOutlined, RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';

import { Dashboard } from './dashboard';
import { Jobs } from './jobs';
import { Searches } from './searches';
import { CV } from './cv';
import { CoverLetters } from './cover-letters';
import { Billing } from './billing';
import { Support } from './support';
import { Settings } from './settings/settings';

import { ReactComponent as SeekyLogoMiniIcon } from './assets/seeky-logo-mini.svg';
import { ReactComponent as SeekyLogoFullIcon } from './assets/seeky-logo-full.svg';
import { ReactComponent as LogoutIcon } from './assets/logout-icon.svg';

import { ReactComponent as DashboardIcon } from './assets/dashboard-icon.svg';
import { ReactComponent as SearchesIcon } from './assets/searches-icon.svg';
import { ReactComponent as CVIcon } from './assets/cv-icon.svg';
import { ReactComponent as CoverLettersIcon } from './assets/cover-letters-icon.svg';
import { ReactComponent as CRMIcon } from './assets/crm-icon.svg';
import { ReactComponent as FollowUpHRIcon } from './assets/follow-up-hr.svg';
import { ReactComponent as DirectApplayngIcon } from './assets/direct-applayng.svg';
import { ReactComponent as RecommendationsIcon } from './assets/recommendations-icon.svg';
import { ReactComponent as JobInterviewsIcon } from './assets/job-iterviews-icon.svg';
import { ReactComponent as BillingIcon } from './assets/billing-icon.svg';
import { ReactComponent as SupportIcon } from './assets/support-icon.svg';
import { ReactComponent as SettingsIcon } from './assets/settings-icon.svg';

import { AppContext, APP_STATE } from '../../AppContext';
import {
    useScreenWidth,
    WIDTH_800_VALUE
} from '../../hooks/use-screen-width';

import { logoutRequest } from '../../api/v1/auth-api';
import { getCurrentUserInfoRequest } from '../../api/v1/users-api';

import './styles.css';

const { Content, Sider } = Layout;

const wrap = (element, route, onClick) => {
    return (
        <div className="menu-item" onClick={() => onClick && onClick(route)}>
            { element }
        </div>
    );
};

const wrapSoon = (element) => {
    return (
        <div className="menu-item menu-item-soon">
            { element }
        </div>
    );
};

const UserBlock = ({ collapsed, username = '', email = '', handleLogout }) => {
    if (collapsed) {
        return (
            <div className="menu-user-collapsed">
                <LogoutIcon style={{ cursor: 'pointer' }} onClick={handleLogout} />
            </div>
        );
    } else {
        return (
            <div className="menu-user">
                <div>
                    <Avatar shape="square" icon={<UserOutlined />} />
                </div>
                <div className="middle">
                    <div className="name">
                        { username }
                    </div>
                    <div className="email">
                        { email }
                    </div>
                </div>
                <div className="right">
                    <LogoutIcon style={{ cursor: 'pointer' }} onClick={handleLogout} />
                </div>
            </div>
        );
    }
};

const getItem = (label, key, icon, children) => { return { key, icon, children, label }; };

export const Main = ({ path }) => {
    const screenWidth = useScreenWidth();
    const [ isMobileMode, setMobileMode ] = useState(window.innerWidth <= WIDTH_800_VALUE);

    const { appState, setAppState, appUser, setAppUser, appToken, setAppToken } = useContext(AppContext);
    const authHeader = useAuthHeader();
    const signOut = useSignOut();
    const navigate = useNavigate();
    // const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        updateAppUser();
    }, []);

    const [ route, setRoute ] = useState(path);
    const [ collapsed, setCollapsed ] = useState(true);

    useEffect(() => {
        const isMobile = screenWidth <= WIDTH_800_VALUE;

        setMobileMode(isMobile);
        if (isMobile) {
            setCollapsed(true);
        }
    }, [ screenWidth ]);

    const [ itemsA ] = useState([
        getItem(wrap('Dashboard', 'dashboard', setRoute), '1', wrap(<DashboardIcon />, 'dashboard', setRoute)),
        getItem(wrap('Searches', 'searches', setRoute), '2', wrap(<SearchesIcon />, 'searches', setRoute)),
        getItem(wrap('CV Settings', 'cv', setRoute), '3', wrap(<CVIcon />, 'cv', setRoute)),
        getItem(wrap('Covers Letters', 'cover-letters', setRoute), '4', wrap(<CoverLettersIcon />, 'cover-letters', setRoute)),
        getItem(wrapSoon('CRM'), '5', <CRMIcon />)
    ]);
    const [ itemsB ] = useState([
        getItem(wrapSoon('Follow-up HR'), '1', <FollowUpHRIcon />),
        getItem(wrapSoon('Direct Applayng'), '2', <DirectApplayngIcon />),
        getItem(wrapSoon('Recommendations'), '3', <RecommendationsIcon />)
    ]);
    const [ itemsC ] = useState([
        getItem(wrapSoon('Job Interviews'), '1', <JobInterviewsIcon />)
    ]);
    const [ itemsD ] = useState([
        getItem(wrap('Billing', 'billing', setRoute), '1', wrap(<BillingIcon />, 'billing', setRoute)),
        getItem(wrap('Support', 'support', setRoute), '2', wrap(<SupportIcon />, 'support', setRoute)),
        getItem(wrap('Settings', 'settings', setRoute), '3', wrap(<SettingsIcon />, 'settings', setRoute))
    ]);

    const updateAppUser = useCallback(() => {
        console.log('update app user');
        getCurrentUserInfoRequest({ auth: authHeader() })
            .then(responseData => {
                if (responseData) {
                    setAppUser({
                        id: responseData?.id,
                        email: responseData?.email,
                        isActive: responseData?.is_active,
                        isVerified: responseData?.is_verified,
                        firstName: responseData?.firstName,
                        lastName: responseData?.lastName,
                        hasSubscription: responseData?.subscription?.status
                            ? responseData?.subscription?.status !== 'never_had'
                            : false,
                        hasVerifiedLinkedin: responseData?.linkedin_profile_is_verified
                    });
                    // console.log('has subsription:', responseData?.subscription?.status);
                } else {
                    signOut();
                }
            });
    }, [ authHeader, setAppUser, signOut ]);

    const handleLogout = () => {
        logoutRequest({ auth: authHeader() })
            .catch(errorData => console.log('logout error:', errorData))
            .finally(() => {
                setAppState(APP_STATE.LOGIN);
                setAppToken({ token: '', type: '' });
                setAppUser({ id: '', email: '', isActive: false, isVerified: false, firstName: '', lastName: '' });
                signOut();
                navigate('/login');
            });
    };

    return (
        <div className="main-page">
            <Layout hasSider style={{ background: '#ffffff' }}>
                <Sider
                    width={320}
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                    style={{
                        width: '320',
                        maxWidth: '320',
                        overflow: 'auto',
                        position: 'fixed',
                        height: '100vh',
                        paddingBottom: '20px'
                    }}
                    theme='light'
                >
                    <Flex
                        vertical
                        justify={'space-between'}
                        style={{
                            minHeight: '400px',
                            height: '100vh',
                        }}
                    >
                        <div>
                            <div className="main-logo">
                                {
                                    collapsed
                                        ? <SeekyLogoMiniIcon style={{ cursor: 'pointer' }} onClick={() => { navigate('/dashboard'); setRoute('dashboard'); }} />
                                        : <SeekyLogoFullIcon style={{ cursor: 'pointer' }} onClick={() => { navigate('/dashboard'); setRoute('dashboard'); }} />
                                }
                            </div>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={['4']}
                                items={itemsA}
                                style={{ border: 'none', textAlign: 'left' }}
                            />
                            <Divider
                                className="menu-title"
                                orientation="left"
                            />
                            <Menu
                                mode="inline"
                                items={itemsB}
                                style={{ border: 'none', textAlign: 'left' }}
                            />
                            <Divider
                                className="menu-title"
                                orientation="left"
                            />
                            <Menu
                                mode="inline"
                                items={itemsC}
                                style={{ border: 'none', textAlign: 'left' }}
                            />
                        </div>
                        <div>
                            <Menu
                                mode="inline"
                                items={itemsD}
                                style={{ border: 'none', textAlign: 'left' }}
                            />
                            <Divider />
                            <UserBlock
                                collapsed={collapsed}
                                username={`${appUser?.firstName || ''} ${appUser?.lastName || ''}`}
                                email={`${appUser?.email}`}
                                handleLogout={handleLogout}
                            />
                        </div>
                    </Flex>

                </Sider>
            </Layout>
            <Layout
                className="main-layout"
                style={{ marginLeft: collapsed ? 80 : 320 }}
            >
                <Content className="main-content">
                    {
                        ! isMobileMode && (
                            <div
                                className="menu-bar-arrow"
                                onClick={() => setCollapsed(! collapsed)}
                            >
                                { collapsed ? (
                                    <div className="right-arrow">
                                        <RightCircleOutlined
                                            className="arrow-icon"
                                            title="Open menu bar"
                                            style={{ color: 'gray' }}
                                        />
                                    </div>
                                ) : (
                                    <div className="left-arrow">
                                        <LeftCircleOutlined
                                            className="arrow-icon"
                                            title="Close manu bar"
                                            style={{ color: 'gray' }}
                                        />
                                    </div>
                                ) }
                            </div>
                        )
                    }
                    { route === 'dashboard' && (
                        <Dashboard auth={authHeader()} />
                    ) }
                    { route === 'jobs' && (
                        <Jobs auth={authHeader()} />
                    ) }
                    { route === 'searches' && (
                        <Searches auth={authHeader()} />
                    ) }
                    { route === 'cv' && (
                        <CV />
                    ) }
                    { route === 'cover-letters' && (
                        <CoverLetters />
                    ) }
                    { route === 'billing' && (
                        <Billing />
                    ) }
                    { route === 'support' && (
                        <Support />
                    ) }
                    { route === 'settings' && (
                        <Settings />
                    ) }
                </Content>
            </Layout>
        </div>
    );
};
