import { useState, useEffect } from 'react';

export const WIDTH_800_VALUE = 800;
export const WIDTH_1000_VALUE = 1000;

export const useScreenWidth = () => {
    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);    
    }, []);

    return screenWidth;
};
