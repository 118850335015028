import React, { useState, useEffect } from 'react';

import { MessageWrapper, SeekyPic } from '../common';

export const QuestionB = ({ fast, options, selectedOption, onStepNext }) => {
    return (
        <div className="message">
            <div className="message-left">
                <SeekyPic
                    fast={fast}
                    delay={1000}
                />
            </div>
            <div className="message-right">
                <QuestionText
                    fast={fast}
                    delay={1000}
                />
                <ButtonsBlock
                    fast={fast}
                    delay={2200}
                    options={options}
                    selectedOption={selectedOption}
                    onGetAnswer={onStepNext}
                />
            </div>            
        </div>
    );
};

const QuestionText = ({ fast, delay }) => {
    return (
        <MessageWrapper
            delay={fast ? 0 : delay}
            body={(state) => (
                <div className={fast ? 'message-item-white-fast' : `message-item-white message-item--${state}`}>
                    <div className="board-item-spec-gray">
                        <span>Seeky AI</span>
                    </div>
                    <div className="text">How long have you been looking for a job?</div>
                </div>
            )}
        />
    );
}

const ButtonsBlock = ({ fast, delay, options, selectedOption, onGetAnswer }) => {
    const [ isBlocked, setBlocked ] = useState(!! selectedOption);
    const [ selected, setSelected ] = useState(selectedOption);

    const handleClick = (item) => {
        if (! isBlocked) {
            setBlocked(true);
            setSelected(item);
        }
    };

    useEffect(() => {
        if (isBlocked && onGetAnswer && typeof onGetAnswer === 'function') {
            onGetAnswer(selected);
        }
    }, [ isBlocked ]);

    return (
        <MessageWrapper
            delay={fast ? 0 : delay}
            body={(state) => (
                <div className={fast ? 'message-item-transparent-fast' : `message-item-transparent message-item--${state}`}>
                    {
                        options?.map((item, index) => {
                            return (item === selected) ? (
                                <div
                                    className={'question-btn question-btn-selected'}
                                    key={`question-a-btn-${index}`}
                                >
                                    {item}
                                </div>
                            ) : (
                                <div
                                    className={`question-btn question-btn-${ isBlocked ? 'passive' : 'active' }`}
                                    key={`question-a-btn-${index}`}
                                    onClick={() => handleClick(item)}
                                >
                                    {item}
                                </div>
                            );
                        })
                    }
                </div>
            )}
        />
    );
};
