import axios, { AxiosError } from 'axios';

import {
    SEEKY_API_V1_AUTH_LOGIN_URL,
    SEEKY_API_V1_AUTH_LOGOUT_URL,
    SEEKY_API_V1_AUTH_REGISTER_URL,
    SEEKY_API_V1_AUTH_REQUEST_VERIFY_TOKEN_URL,
    SEEKY_API_V1_AUTH_VERIFY_URL
} from '../../constants';

export const ERROR_RESPONSE_VALUES = {
    UNKNOWN: 1,
    LOGIN_BAD_CREDENTIALS: 2,
    VALIDATION: 3
};

export const ERROR_REGISTER_VALURES = {
    INVALID_PASSWORD: 'REGISTER_INVALID_PASSWORD',
    USER_EXISTS: 'REGISTER_USER_ALREADY_EXISTS'
};

export const loginRequest = async (values) => {
    return axios
        .post(SEEKY_API_V1_AUTH_LOGIN_URL,
            { username: values.email, password: values.password },
            { headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'accept': 'application/json'
            } }
        )
        .catch(e => {
            const error = { code: ERROR_RESPONSE_VALUES.UNKNOWN };

            if (e && e instanceof AxiosError) {
                if (e.response.status === 400) {
                    error.code = ERROR_RESPONSE_VALUES.LOGIN_BAD_CREDENTIALS;
                } else if (e.response.status === 422 ) {
                    error.code = ERROR_RESPONSE_VALUES.VALIDATION;
                }
            }
            return Promise.reject(error);
        })
        .then(response => {
            return {
                token: response?.data?.access_token,
                type: response?.data?.token_type
            };
        });
};

export const logoutRequest = async ({ auth }) => {
    return axios
        .post(SEEKY_API_V1_AUTH_LOGOUT_URL,
            {},
            { headers: {
                'accept': 'application/json',
                'Authorization': auth
            } }
        )
        .catch(() => {
            return Promise.reject('Missing token or inactive user.');
        });    
};

export const createRequest = async ({ username, email, password, isGdprAccepted, isMailingsAccepted }) => {
    return axios
        .post(SEEKY_API_V1_AUTH_REGISTER_URL, {
                email: email,
                password: password,
                first_name: username,
                gdpr: isGdprAccepted,
                marketing_mailings: isMailingsAccepted
            }, { headers: {
                'content-type': 'application/json',
                'accept': 'application/json'
            } }
        )
        .then(() => true)
        .catch(e => {
            let error = 'Ops! An error has occurred. Try again later.';

            if (e && e instanceof AxiosError) {
                if (e.response.status === 400) {
                    if (e.response.data?.detail === ERROR_REGISTER_VALURES.USER_EXISTS) {
                        error = 'Ops! User already exists. Please login or try register with another credentials.';
                    } else if (e.response.data?.detail?.code === ERROR_REGISTER_VALURES.INVALID_PASSWORD) {
                        error = 'Ops! An incorrect password was entered.';
                    }
                } else if (e.response.status === 422 ) {
                    error = 'Ops! Validation error.';
                }
            }

            return Promise.reject(error);
        });
};

export const sendTokenToEmailRequest = async (email) => {
    return axios
        .post(SEEKY_API_V1_AUTH_REQUEST_VERIFY_TOKEN_URL,
            { email: email },
            { headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
            } }
        )
        .then(() => Promise.resolve(true))
        .catch(() => Promise.resolve(false));
};

export const verifyTokenFromEmailRequest = async (token) => {
    console.log('verify token from email request', token)
    return axios
        .post(SEEKY_API_V1_AUTH_VERIFY_URL,
            { token: token },
            {
                'content-type': 'application/x-www-form-urlencoded',
                'accept': 'application/json'
            }
        )
        .then(() => true) //Promise.resolve(true))
        .catch(() => Promise.reject());
};
