import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown } from 'antd';

import { StatusMapper } from './mappers/status-mapper';

import { requestJobIdUpdateStatus } from '../../../../api/v1/jobs-api';

const items = [
    { key: '1', label: <StatusMapper status='New' />, state: 'New' },
    { key: '2', label: <StatusMapper status='Viewed' />, state: 'Viewed' },
    { key: '3', label: <StatusMapper status='Applied' />, state: 'Applied' },
    { key: '4', label: <StatusMapper status='First Contact' />, state: 'First Contact' },
    { key: '5', label: <StatusMapper status='Interview' />, state: 'Interview' },
    { key: '6', label: <StatusMapper status='Test Case' />, state: 'Test Case' },
    { key: '7', label: <StatusMapper status='Second Interview' />, state: 'Second Interview' },
    { key: '8', label: <StatusMapper status='Got Offer' />, state: 'Got Offer' },
    { key: '9', label: <StatusMapper status='Denied' />, state: 'Denied' }
];

export const Status = ({ auth, jobId, initialStatus }) => {
    const [ currentKey, setCurrentKey ] = useState();
    const [ activeStatus, setActiveStaus ] = useState(<StatusMapper status={initialStatus} />);

    const handleClick = useCallback((e) => {
        setCurrentKey(e.key)
    }, [ setCurrentKey ]);

    useEffect(() => {
        if (currentKey) {
            const item = items.find(element => element.key === currentKey);

            setActiveStaus(item?.label);

            requestJobIdUpdateStatus({ auth, id: jobId, actualStatus: item.state})
                .then(() => {})
                .catch(() => {});
        }
    }, [ currentKey ]);

    return (
        <div>
            <Dropdown
                menu={{ items, onClick: handleClick }}
                placement="bottomLeft"
                arrow
            >
                <a onClick={(e) => e.preventDefault()}>
                    { activeStatus }
                </a>
            </Dropdown>
        </div>
    );
};