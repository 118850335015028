import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { useAuthHeader, useSignIn, useSignOut, useIsAuthenticated } from 'react-auth-kit';

import { useFormik } from 'formik';

import { AppContext, APP_STATE } from '../../AppContext';

import { Header } from '../../components/header';
import { ReactComponent as SeekyPageLogo } from '../../assets/seeky-page-logo.svg';

import { loginRequest } from '../../api/v1/auth-api';
import { getCurrentUserInfoRequest } from '../../api/v1/users-api';

import '../common/styles.css';
import './styles.css';

export const LoginPage = () => {
    return (
        <div className="Page LoginPage">
            <Header />
            <Body />
        </div>
    );
};

const Body = () => {
    const { appState, setAppState, appToken, setAppToken, appUser, setAppUser } = useContext(AppContext);
    const [ error, setError ] = useState(null);

    const navigate = useNavigate();
    const authHeader = useAuthHeader();
    const signIn = useSignIn();
    const signOut = useSignOut();
    const isAuthenticated = useIsAuthenticated();

    const onSubmit = (values) => {
        setError(null);
        loginRequest(values)
            .then(responseData => {
                const token = {
                    token: responseData?.token,
                    type: responseData?.type
                };

                signIn({
                    token: token.token,
                    tokenType: token.type,
                    expiresIn: 3600,
                    authState: { email: values.email }
                });
                setAppToken(token);
                setAppState(APP_STATE.LOGIN_OK);
                setAppUser({ id: '', email: '', isActive: false, isVerified: false, firstName: '', lastName: '', hasSubscription: false });
            })
            .catch(errorData => {
                setError(errorData);
            });
    };

    const updateAppUser = useCallback(() => {
        getCurrentUserInfoRequest({ auth: authHeader() })
            .then(responseData => {
                if (responseData) {
                    setAppUser({
                        id: responseData?.id,
                        email: responseData?.email,
                        isActive: responseData?.is_active,
                        isVerified: responseData?.is_verified,
                        firstName: responseData?.firstName,
                        lastName: responseData?.lastName,
                        hasSubscription: responseData?.subscription?.status
                            ? responseData?.subscription?.status !== 'never_had'
                            : false,
                        hasVerifiedLinkedin: responseData?.linkedin_profile_is_verified
                    });
                    console.log('has subsription:', responseData?.subscription?.status);
                } else {
                    signOut();
                }
            });
    }, [ authHeader, setAppUser, signOut ]);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit
    });

    useEffect(() => {
        if (appState === APP_STATE.INDEX) {
            if (isAuthenticated()) {
                if (! appUser.id) {
                    updateAppUser();
                } else {
                    if (appUser.hasSubscription) {
                        if (appUser.isVerified) {
                            setAppState(APP_STATE.WELCOME);
                            navigate('/welcome');    
                        } else {
                            setAppState(APP_STATE.VERIFY);
                            navigate('/verify');
                        }
                    } else {
                        setAppState(APP_STATE.BILLING);
                        navigate('/billing');
                    }
                }
            } else {
                setAppState(APP_STATE.LOGIN);
            }
        } else if (appState === APP_STATE.LOGIN_OK) {
            if (! appUser.id) {
                updateAppUser();
            } else {
                setAppState(APP_STATE.INDEX);
            }
        }
    }, [ appState, appToken, appUser, setAppState, setAppUser, authHeader, isAuthenticated, navigate, updateAppUser ]);

    return (
        <div className="Page-body">
            <div className="Page-card">
                <div className="Page-logo">
                    <SeekyPageLogo />
                </div>
                {appState !== APP_STATE.INDEX && (
                    <Fragment>
                        <h1>Log&nbsp;in</h1>
                        <p>Welcome back! Please enter your details.</p>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    id="email"
                                    type="email"
                                    placeholder="Enter your email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    id="password"
                                    type="password"
                                    placeholder="Enter your a password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>
                        </Form>
                        <Button className="form-btn" onClick={formik.handleSubmit}>
                            Log&nbsp;in
                        </Button>
                        { error?.code ? (
                            <div className="error-text-msg">
                                There is an error in logging you into this application. Please try again later!
                            </div>
                        ) : null }
                        <div className="footer-block">
                            <p className="info cntr">
                                Don't have account? <Link className="link" to="/sign-up">Sign up</Link>
                            </p>
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
};
