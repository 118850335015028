import axios from 'axios';

import {
    SEEKY_API_V1_LINKEDIN_FETCH_URL,
    SEEKY_API_V1_LINKEDIN_VERIFY_URL,
    SEEKY_API_V1_LINKEDIN_ADVICE_URL,
} from '../../constants';

export const fetchLinkedinProfileRequest = async ({ profileUrl, auth }) => {
    return axios
        .post(SEEKY_API_V1_LINKEDIN_FETCH_URL,
            { 'profile_url': profileUrl },
            { headers: {
                'content-type': 'application/json',
                'accept': 'application/json',
                'Authorization': auth
            } }
        )
        .then(response => {
            const person = response?.data?.person;

            return {
                firstName: person?.first_name || '',
                lastName: person?.last_name || '',
                location: person?.location || '',
                photoUrl: person?.profile_photo_url || '',
                headline: person?.headline || ''
            };
        })
        .catch(e => {
            const error = {
                code: e?.response?.data?.error_code || 0,
                detail: e?.response?.data?.detail || '',
                message: ''
            };

            console.log(error);
            switch (error.code) {
                case 8:
                    error.message = 'Cannot parse linkedin profile info [8]';
                    break;

                case 39:
                    error.message = 'Profile linked to another user [39]';
                    break;

                default:
                    error.message = 'An error has occurred';
                    break;
            }

            return Promise.reject(error);
        });
};

export const verifyLinkedinProfileRequest = async ({ auth }) => {
    console.log('verify auth=', auth);

    return axios
        .post(SEEKY_API_V1_LINKEDIN_VERIFY_URL,
            {},
            { headers: {
                'accept': 'application/json',
                'Authorization': auth
            } }
        )
        .then(response => {
            return response;
        })
        .catch(() => Promise.reject());
};

export const adviceLinkedinProfileRequest = async ({ auth }) => {
    return axios
        .post(SEEKY_API_V1_LINKEDIN_ADVICE_URL,
            {},
            { headers: {
                'accept': 'application/json',
                'Authorization': auth
            } }
        )
        .then(response => {
            return response?.data;
        })
        .catch(() => {
            return Promise.reject();
        });
};
