import axios from 'axios';

import {
    SEEKY_API_V1_JOBS_TITLES_URL,
    SEEKY_API_V1_JOBS_URL
} from '../../constants';

export const getJobsTitlesRequest = async ({ auth, filterByName }) => {
    const param = filterByName ? `?filter_by_name=${ filterByName }` : '';

    return axios
        .get(`${SEEKY_API_V1_JOBS_TITLES_URL}${param}`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => {
            return response?.data || [];
        })
        .catch(() => { return Promise.reject(); });
};

export const requestGetJobsList = async ({ auth, lessThan, greaterThan, limit = 10 }) => {
    const params = [];

    limit && params.push(`limit=${limit}`);

    if (lessThan) {
        params.push(`less_than=${lessThan}`);
    } else if (greaterThan) {
        params.push(`greater_than=${greaterThan}`);
    }

    return axios
        .get(`${SEEKY_API_V1_JOBS_URL}?${params.join('&')}`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => {
            return response?.data || [];
        })
        .catch(() => { return Promise.reject(); });
};

export const getJobByIdRequest = async ({ auth, id }) => {
    // console.log('=>', `${SEEKY_API_V1_JOBS_URL}/${ id || '0' }`);
    return axios
        .get(`${SEEKY_API_V1_JOBS_URL}/${ id || '0' }`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => {
            return response?.data;
        })
        .catch(() => { return Promise.reject(); });
};

export const requestJobIdUpdateStatus = async ({ auth, id, actualStatus }) => {
    // console.log('api: request jobId update status');
    // console.log(` - ${SEEKY_API_V1_JOBS_URL}/${ id || '0' }/status`);
    // console.log(' -',auth, '|', id, '|', actualStatus);

    const url = `${SEEKY_API_V1_JOBS_URL}/${ id || '0' }/status`;
    return axios({
        method: 'patch',
        url: url,
        data: {
            status: actualStatus
        },
        headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': `${ auth }`
        }
    })
        .then(response => response)
        .catch((e) => {
            console.log('err', e);
            return Promise.reject();
        });
};

/**
 * GET: /api/v1/jobs/total - Returns jobs total.
 */
export const requestGetJobsTotal = async ({ auth }) => {
    return axios
        .get(`${SEEKY_API_V1_JOBS_URL}/total`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => {
            return response?.data;
        })
        .catch(() => Promise.reject());
};


/**
 * GET: /api/v1/jobs/best/percent
 * Returns best jobs percent
 */
export const requestGetJobsBestPercent = async ({ auth }) => {
    return axios
        .get(`${SEEKY_API_V1_JOBS_URL}/best/percent`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => {
            return response?.data;
        })
        .catch(() => Promise.reject());
};

/**
 * POST: /api/v1/job/{ user_to_job_id }/pdf/cv
 */
export const requestGetCvPdfByJobId = async ({ auth, id }) => {
    return axios
        .post(`/api/v1/job/${ id }/pdf/cv`,
            {
                "theme_name": "base"
            },
            {
                headers: {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': `${ auth }`
                },
                responseType: 'blob'
            }
        )
        .then(response => {
            return response?.data;
        })
        .catch(() => Promise.reject());
};

/**
 * POST: /api/v1/job/{ user_to_job_id }/cover_letter
 * Make CV
 */
export const requestGetClTextfByJobId = async ({ auth, id }) => {
    return axios
        .post(`/api/v1/job/${ id }/cover_letter`,
            {},
            { headers: {
                'content-type': 'application/json',
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => {
            return response?.data;
        })
        .catch(() => Promise.reject());
};
