import React from 'react';
import { Tag } from 'antd';

const getColor = (status) => {
    switch (status) {
        case 'New':
            return 'blue';

        case 'Viewed':
        case 'First Contact':
        case 'Interview':
        case 'Test Case':
        case 'Second Interview':
        case 'Got Offer':
            return 'default';

        case 'Applied':
            return 'green';

        case 'Denied':
            return 'red';

        default: 
            return 'default';
    }
};

export const StatusMapper = ({ status }) => {
    if (! status) {
        return null;
    }

    return (
        <div className="status-mapper">
            <Tag
                color={getColor(status)}
                icon={<>⦿{' '}</>}
            >
                { status }
            </Tag>
        </div>
    );
};
