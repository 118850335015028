import axios from 'axios';

import {
    SEEKY_API_V1_USERS_ME_URL
} from '../../constants';

export const getCurrentUserInfoRequest = ({ auth }) => {
    return axios
        .get(SEEKY_API_V1_USERS_ME_URL,
            { headers: {
                'accept': 'application/json',
                'Authorization': auth
            } }
        )
        .then(response => {
            return response?.data;
        })
        .catch(e => {
            return null;
        });
};