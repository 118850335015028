import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link, useParams } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { Breadcrumb, Typography, Space, Spin } from 'antd';

import { ReactComponent as HomeIcon } from '../assets/home-icon.svg';
import { ReactComponent as CvIcon } from './assets/cv-icon.svg';
import { ReactComponent as ClIcon } from './assets/cl-icon.svg';
import { ReactComponent as PinIcon } from './assets/icon-pin.svg';
import { ReactComponent as HouseIcon } from './assets/icon-house.svg';
import { ReactComponent as EmployeeIcon } from './assets/icon-employee.svg';

import { AppContext } from '../../../AppContext';
import { useScreenWidth, WIDTH_1000_VALUE } from '../../../hooks/use-screen-width';

import {
    getJobByIdRequest,
    requestGetCvPdfByJobId,
    requestGetClTextfByJobId,
    requestJobIdUpdateStatus
} from '../../../api/v1/jobs-api';

import './styles.css';

const { Title } = Typography;

export const Jobs = ({ auth }) => {
    const screenWidth = useScreenWidth();
    const { appUser } = useContext(AppContext);
    let { id } = useParams();

    const [ data, setData ] = useState({});

    useEffect(() => {
        getJobByIdRequest({ auth, id })
            .then(responseData => {
                setData({ ...responseData });

                if (responseData?.status === 'New') {
                    requestJobIdUpdateStatus({ auth, id, actualStatus: 'Viewed' })
                        .then(() => console.log(`job #${ id } changed status to 'Viewed'`))
                        .catch(() => console.log('Error: cannot change status'));
                }
            })
            .catch((e) => {
                // TODO: notification
                console.log('get job by id: error', e);
            });
    }, [ id ]);

    return (
        <div className="jobs-page">
            <Space direction="vertical" size='large' style={{ width: '100%' }}>
                <Breadcrumb
                    separator="&nbsp;&nbsp;>&nbsp;&nbsp;"
                    items={[
                        { title: <HomeIcon /> },
                        { title: 'View' },
                        { title: 'Jobs' },
                        { title: `${ id }` }
                    ]}
                />
                <Space direction="horizontal" size='large' style={{ width: '100%', alignItems: 'start' }}>
                    <JobInfo
                        auth={auth}
                        data={data}
                        id={id}
                        screenWidth={screenWidth}
                    />
                    { screenWidth > WIDTH_1000_VALUE
                        && <CompanyInfo
                            auth={auth}
                            data={data}
                        />
                    }
                </Space>
                
          </Space>
        </div>
    );
};

const JobInfo = ({ auth, data, id, screenWidth }) => {
    const [ pdf, setPdf ] = useState(null);
    const [ text, setText ] = useState(null);

    const [ isCvDisabled, setCvDisabled ] = useState(false);
    const [ isCvInProcessing, setCvInProcessing ] = useState(false);

    const [ isClDisabled, setClDisabled ] = useState(false);
    const [ isClInProcessing, setClInProcessing ] = useState(false);

    const handleGenerateCv = useCallback(() => {
        setCvDisabled(true);
        setCvInProcessing(true);

        requestGetCvPdfByJobId({ auth, id })
            .then(responsePdf => {
                setCvInProcessing(false);
                setPdf(responsePdf);
            })
            .catch(() => {
                setCvDisabled(false);
                setCvInProcessing(false);
            });
    }, []);

    const handleGenerateCl = useCallback(() => {
        setClDisabled(true);
        setClInProcessing(true);

        requestGetClTextfByJobId({ auth, id })
            .then(responseText => {
                setClInProcessing(false);
                setText(responseText);
            })
            .catch(() => {
                setClDisabled(false);
                setClInProcessing(false);
            });
    }, []);

    const downloadCv = () => {
        const element = document.createElement("a");
        const file = new Blob([ pdf ], { type: 'application/pdf' });
        element.href = URL.createObjectURL(file);
        element.download = "cv.pdf";
        
        window.open(URL.createObjectURL(file));
    };

    const downloadCl = () => {
        const element = document.createElement("a");
        const file = new Blob([ text ], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "cl.txt";
        
        window.open(URL.createObjectURL(file));
    };

    return (
        <Space direction="vertical" size='large' style={{ width: '100%' }}>
            <div className="info-block">
                <Space direction="vertical" size='middle' style={{ width: '100%' }}>
                    <Title level={5} style={{ margin: '0'}}>
                        { data?.job_name }
                    </Title>
                    <div>
                        { data?.region && (
                            <PinIcon />
                        ) }
                        <span style={{ marginLeft: '0.5em', verticalAlign: 'bottom' }}>
                            { data?.region }
                        </span>
                    </div>
                    <Link
                        disabled={ data?.job_url ? false : true }
                        to={ data?.job_url }
                        target='_blank'
                    >
                        <Button disabled={ data?.job_url ? false : true }>
                            Apply
                        </Button>
                    </Link>                    
                </Space>
            </div>
            { screenWidth <= WIDTH_1000_VALUE && (
                <CompanyInfo
                    auth={auth}
                    data={data}
                    fullWidth={true}
                />
            ) }
            <div className="info-block">
                <Space direction="vertical" size='small' style={{ width: '100%' }}>
                    <Title level={5} style={{ margin: '0'}}>
                        About the job
                    </Title>
                    <div dangerouslySetInnerHTML={{__html: data?.description}}>
                    </div>
                </Space>
            </div>
            <div className="info-block">
                <Space direction="vertical" size='middle' style={{ width: '100%' }}>
                    <Title level={5} style={{ margin: '0'}}>
                        CV
                    </Title>
                    <div className="info-block-1">
                        <Space direction="horizontal" size='middle' style={{ width: '100%', alignItems: 'start' }}>
                            <CvIcon />
                            <div>
                                <Space direction="vertical" size='small' style={{ width: '100%' }}>
                                    <Title level={5} style={{ margin: '0'}}>
                                        Generate your CV
                                    </Title>
                                    <div>
                                        You can start generating a resume if you have already added it in the CV settings.
                                    </div>
                                </Space>
                            </div>
                        </Space>
                    </div>
                    <div>
                        { !isCvInProcessing && pdf
                            ? null
                            : <Button
                                onClick={handleGenerateCv}
                                disabled={isCvDisabled}
                            >
                                Generate CV
                            </Button>
                        }
                        { isCvInProcessing && (
                            <span style={{ marginLeft: '1em' }}>
                                <Spin />
                            </span>
                        ) }
                        { !isCvInProcessing && pdf && (
                            <Button onClick={downloadCv}>
                                Download CV (Pdf)
                            </Button>
                        )}
                    </div>
                </Space>
            </div>
            <div className="info-block">
                <Space direction="vertical" size='middle' style={{ width: '100%' }}>
                    <Title level={5} style={{ margin: '0'}}>
                        Cover letter
                    </Title>
                    <div className="info-block-1">
                        <Space direction="horizontal" size='middle' style={{ width: '100%' }}>
                            <ClIcon />
                            <div>
                                <Space direction="vertical" size='small' style={{ width: '100%' }}>
                                    <Title level={5} style={{ margin: '0'}}>
                                        Generate your Cover letter
                                    </Title>
                                    <div>
                                        You can start generating a resume if you have already added it in the CL settings.
                                    </div>
                                </Space>
                            </div>
                        </Space>
                    </div>
                    <div>
                        { !isClInProcessing && text
                            ? null
                            : <Button
                                onClick={handleGenerateCl}
                                disabled={isClDisabled}
                            >
                                Generate Cover letter
                            </Button>
                        }
                        { isClInProcessing && (
                            <span style={{ marginLeft: '1em' }}>
                                <Spin />
                            </span>
                        ) }
                        { !isClInProcessing && text && (
                            <Button onClick={downloadCl}>
                                Download Letter (Txt)
                            </Button>
                        )}
                    </div>
                </Space>
            </div>
            <br />
        </Space>
    )
};

const CompanyInfo = ({ auth, data, fullWidth }) => {
    return (
        <div className="info-block" style={{ width: fullWidth ? '100%' : '300px' }}>
            <Space direction="vertical" size='small' style={{ width: '100%' }}>
                <Title level={5} style={{ margin: '0'}}>
                    Company
                </Title>
                <Space direction="horizontal" size='small'>
                    <HouseIcon />
                    <div>{ data?.company_name }</div>
                </Space>
                <Space direction="horizontal" size='small'>
                    <PinIcon />
                    <div>{ data?.region }</div>
                </Space>
                <Space direction="horizontal" size='small'>
                    <EmployeeIcon />
                    <div>100-1000 Employees</div>
                </Space>
            </Space>
        </div>
    );
};
