import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Breadcrumb, Typography, Col, Row, Space } from 'antd'; // Table

import { Status } from './status';

import { ReactComponent as HomeIcon } from '../assets/home-icon.svg';

import { requestGetListOfSearchFilters } from '../../../api/v1/search-filters-api';

import './styles.css';

const { Title } = Typography;

const columns = [
    { title: '', dataIndex: 'name', align: 'left' },
    { title: '', dataIndex: 'location', align: 'left' },
    { title: '', dataIndex: 'date', align: 'left' }
];

const data = [{
    name: <div>Lead Designer</div>,
    location: <div>Melbourne</div>,
    data: <div>22 jun</div>
}];

export const Searches = ({ auth }) => {
    const [ list, setList ] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (navigate) navigate('/searches', { replace: true });
    }, [navigate]);

    useEffect(() => {
        requestGetListOfSearchFilters({ auth })
            .then(data => {
                setList(data);
            })
            .catch(e => console.log('err', e));
    }, []);

    return (
        <div className="searches-page">
            <Space direction="vertical" size='large' style={{ width: '100%' }}>
                <Breadcrumb
                    separator="&nbsp;&nbsp;>&nbsp;&nbsp;"
                    items={[
                        { title: <HomeIcon /> },
                        { title: 'Searches' }
                    ]}
                />
                <div style={{ width: '100%'}}>
                    <Row>
                        <Col flex="auto">
                            <Title level={2} style={{ margin: '0'}}>Your Searches</Title>
                        </Col>
                        <Col flex="150px">
                            <Link to='/create'>
                                <Button>+ New Search</Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
                { list && list.length > 0 && <Info auth={auth} list={list} /> }
            </Space>
        </div>
    );
};

const Info = ({ auth, list }) => {
    return (
        <Space direction="vertical" size='small' style={{ width: '100%' }}>
            {
                list.map((item, key) => (
                    <div className="info-block">
                        <div size='small' style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <div className="block-a-name">
                                { item?.keywords }
                            </div>
                            <div className="block-b-location">
                                { `${ item?.country }, ${ item?.region }${ item?.city ? (', ' + item?.city ) : ''}` }
                            </div>
                            <div className="block-c-status">
                                <Status
                                    auth={auth}
                                    filterId={item?.id}
                                    initialStatus={item?.status}
                                />
                            </div>
                        </div>
                    </div>
                ))
            }
        </Space>
    );
};
