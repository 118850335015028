import React from 'react';
import { Link } from "react-router-dom";

import './styles.css';

export const CompanyMapper = ({ company, userToJobId }) => {
    if (! company) {
        return null;
    }

    return (
        <div
            className="company-mapper"
            style={{
                display: 'flex'
            }}
        >
            <div className="company-info">
                <div
                    className="title"
                    style={{ display: 'block' }}
                >
                    { company?.title
                        ? (
                            <Link
                                target="_blank"
                                to={`/jobs/${userToJobId}`}
                                style={{
                                    textDecoration: 'none',
                                    color: '#444',
                                    fontWeight: 'bold'
                                }}
                                title={ company.title }
                            >
                                { company?.title?.replace('&amp;', ' ') }
                            </Link>
                        ) : '-'
                    }
                </div>
                <div
                    className="sub-title"
                    style={{ display: 'block' }}
                >
                    { company?.url
                        ? (
                            <Link
                                to={`${company.url}`}
                                target='_blank'
                                style={{
                                    textDecoration: 'none',
                                    color: '#666'
                                }}
                                title={ company.name || '' }
                            >
                                { company?.name?.replace('&amp;', ' ') || 'Company URL' }
                            </Link>
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
};

/*
<div className="company-logo">
    <div style={{
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        background: 'white',
        border: '2px solid rgb(221, 221, 221)'
    }}>
    </div>
</div>
*/