import React from 'react';

import { MessageWrapper } from '../common';

export const Message = ({ isFast, delay, isWhiteMsg = false, withBreaker = false, headMsg, bodyMsg }) => {
    return (
        <MessageWrapper
            delay={isFast ? 0 : delay}
            body={(state) => (
                <div className={
                    isFast
                        ? `${ withBreaker ? 'message-breaker' : ''} message-item${ isWhiteMsg ? '-white' : '' }-fast`
                        : `${ withBreaker ? 'message-breaker' : ''} message-item${ isWhiteMsg ? '-white' : '' } message-item--${state}`
                    }>
                    <div className={`board-item-spec${ isWhiteMsg ? '-gray' : ''}`}>
                        { headMsg }
                    </div>
                    { bodyMsg }
                </div>
            )}
        />
    );
};
