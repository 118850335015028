import React, { useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { MessageWrapper, SeekyPic } from '../common';

export const VerifyLinkedin = ({ fast, options, selectedOption, onStepNext }) => {
    return (
        <div className="message">
            <div className="message-left">
                <SeekyPic
                    fast={fast}
                    delay={1000}
                />
            </div>
            <div className="message-right">
                <Block
                    fast={fast}
                    delay={1000}
                    options={options}
                    selectedOption={selectedOption}
                    onGetAnswer={onStepNext}
                />
            </div>            
        </div>
    );
};

const Block = ({ fast, delay, onGetAnswer }) => {
    return (
        <MessageWrapper
            delay={fast ? 0 : delay}
            body={(state) => (
                <div className={fast ? 'message-breaker message-item-white-fast' : `message-breaker message-item-white message-item--${state}`}>
                    <div className="board-item-spec-gray">
                        <span>Seeky AI</span>
                    </div>
                    <div className="text">I need to verify that your LinkedIn user profile meets standard criteria.</div>
                    <div className="text">Please enter your LinkedIn profile URL:</div>
                    <div className="text">
                        <Form.Label className="label">Linkedin URL</Form.Label>
                        <div className="text">
                            <Form.Control size="m" type="text" placeholder="Large text" />
                        </div>                        
                        <Button onClick={() => onGetAnswer()}>Verify</Button>
                    </div>
                </div>
            )}
        />
    );
};
