import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown } from 'antd';

import { StatusMapper } from './status-mapper';

import { requestPatchSearchStatusById } from '../../../api/v1/search-filters-api';

const items = [
    { key: '1', label: <StatusMapper status='Active' />, state: 'Active' },
    // { key: '2', label: <StatusMapper status='Stopped' />, state: 'Stopped' },
    { key: '3', label: <StatusMapper status='Archived' />, state: 'Archived' }
];

export const Status = ({ auth, filterId, initialStatus }) => {
    const [ currentKey, setCurrentKey ] = useState();
    const [ activeStatus, setActiveStaus ] = useState(<StatusMapper status={initialStatus} />);

    const handleClick = useCallback((e) => {
        setCurrentKey(e.key);
    }, [ setCurrentKey ]);

    useEffect(() => {
        if (currentKey) {
            const item = items.find(element => element.key === currentKey);

            requestPatchSearchStatusById({ auth, filterId, filterStatus: item.state})
                .then(() => {
                    setActiveStaus(item?.label);
                })
                .catch(() => {
                    console.log('Cannot change status');
                });
        }
    }, [ currentKey ]);

    return (
        <div>
            <Dropdown
                menu={{ items, onClick: handleClick }}
                placement="bottomLeft"
                arrow
            >
                <a onClick={(e) => e.preventDefault()}>
                    { activeStatus }
                </a>
            </Dropdown>
        </div>
    );
};