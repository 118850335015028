import React from 'react';

export const APP_STATE = {
    INDEX: 'APP_STATE_INDEX',
    LOGIN: 'APP_STATE_LOGIN',
    LOGIN_OK: 'APP_STATE_LOGIN_OK',
    BILLING: 'APP_STATE_BILLING',
    VERIFY: 'APP_STATE_VERIFY',
    WELCOME: 'APP_STATE_WELCOME_SETUP_MASTER',
    CREATE: 'APP_STATE_CREATE',
    MAIN: 'APP_STATE_MAIN_DASHBOARD'
};

export const AppContext = React.createContext({
    appState: '',
    setState: () => {},

    appToken: { token: '', type: '' },
    setAppToken: () => {},

    appUser: { id: '', email: '', isActive: false, isVerified: false, firstName: '', lastName: '', hasSubscription: false, hasVerifiedLinkedin: false },
    setAppUser: () => {}
});
