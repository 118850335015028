import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RequireAuth } from 'react-auth-kit';

import { AppContext, APP_STATE } from './AppContext';

import { LoginPage } from './pages/login';
import { SignUpPage } from './pages/signup';
import { VerifyPage } from './pages/verify';
import { BillingPage, BillingCallbackPage } from './pages/billing';
import { WelcomePage } from './pages/welcome';
import { WelcomePage2 } from './pages/welcome2';
import { CreatePage } from './pages/create';
import { Http404Page } from './pages/http404';

import { Main } from './pages/main';

import './App.css';

const App = () => {
    const [ appState, setAppState ] = useState(APP_STATE.INDEX);
    const [ appToken, setAppToken ] = useState({ token: '', type: '' });
    const [ appUser, setAppUser ] = useState({ id: '', email: '', isActive: false, isVerified: false, firstName: '', lastName: '', hasSubscription: false });
    const value = {
        appState, setAppState,
        appToken, setAppToken,
        appUser, setAppUser
    };

    return (
        <div className="App">
            <AppContext.Provider value={value}>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/sign-up" element={<SignUpPage />} />
                    <Route path="/verify" element={<VerifyPage />} />

                    <Route path="/billing/cb" element={
                        <RequireAuth loginPath="/login">
                            <BillingCallbackPage />
                        </RequireAuth>
                    } />

                    <Route path="/billing" element={
                        <RequireAuth loginPath="/login">
                            <BillingPage />
                        </RequireAuth>
                    } />

                    <Route path="/welcome-old" element={
                        <RequireAuth loginPath="/login">
                            <WelcomePage />
                        </RequireAuth>
                    } />

                    <Route path="/welcome" element={
                        <RequireAuth loginPath="/login">
                            <WelcomePage2 />
                        </RequireAuth>
                    } />

                    <Route path="/create" element={
                        <RequireAuth loginPath="/login">
                            <CreatePage />
                        </RequireAuth>
                    } />

                    <Route path="/dashboard" element={
                        <RequireAuth loginPath="/login">
                            <Main path={'dashboard'} />
                        </RequireAuth>
                    } />

                    <Route path="/searches" element={
                        <RequireAuth loginPath="/login">
                            <Main path={'searches'} />
                        </RequireAuth>
                    } />

                    <Route path="/cv" element={
                        <RequireAuth loginPath="/login">
                            <Main path={'cv'} />
                        </RequireAuth>
                    } />

                    <Route path="/cover-letters" element={
                        <RequireAuth loginPath="/login">
                            <Main path={'cover-letters'} />
                        </RequireAuth>
                    } />

                    <Route path="/billing" element={
                        <RequireAuth loginPath="/login">
                            <Main path={'billing'} />
                        </RequireAuth>
                    } />

                    <Route path="/support" element={
                        <RequireAuth loginPath="/login">
                            <Main path={'support'} />
                        </RequireAuth>
                    } />

                    <Route path="/settings" element={
                        <RequireAuth loginPath="/login">
                            <Main path={'settings'} />
                        </RequireAuth>
                    } />

                    <Route path="/jobs/:id" element={
                        <RequireAuth loginPath="/login">
                            <Main path={'jobs'} />
                        </RequireAuth>
                    } />
                    <Route path="/jobs" element={<Navigate to="/dashboard" />} />

                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/signup" element={<Navigate to="/sign-up" />} />
                    <Route path="/404" element={<Http404Page />} />
                    <Route path="*" element={<Navigate to="/404" />} />
                </Routes>
            </AppContext.Provider>
        </div>
    );
};

export default App;

/*

<Route path="/welcome2" element={
    <RequireAuth loginPath="/login">
        <WelcomePage2 />
    </RequireAuth>
} />

*/
