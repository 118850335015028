import React from 'react';

import { MessageWrapper } from '../common';

import picSeeky from '../../assets/seeky.png'; // TODO: change pic

export const SeekyPic = ({ isFast, delay}) => {
    return (
        <MessageWrapper
            delay={isFast ? 0 : delay}
            body={(state) => (
                <img
                    className={isFast ? 'img-fast ' : `img img--${state}`}
                    src={picSeeky}
                    alt="Seeky"
                />
            )}
        />
    );
};
