import React, { useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthHeader, useSignOut, useIsAuthenticated } from 'react-auth-kit';
// import linkedInTag  from 'react-linkedin-insight';

import { AppContext, APP_STATE } from '../../AppContext';

import { Header } from '../../components/header';
// import { LINKEDIN_PARTNER_ID } from '../../constants';

import { SetupMaster } from './setup-master';

import { getCurrentUserInfoRequest } from '../../api/v1/users-api';

import '../common/styles.css';
import './styles.css';
// import './steps/styles.css';

export const WelcomePage2 = () => {
    const { appState, setAppState, appUser, setAppUser, appToken, setAppToken } = useContext(AppContext);

    const navigate = useNavigate();
    const authHeader = useAuthHeader();    
    const isAuthenticated = useIsAuthenticated();
    const signOut = useSignOut();

    const auth = authHeader();

    useEffect(() => {
        if (auth) {
            const tp = auth.split(' ').at(0);
            const to = auth.split(' ').at(1);

            setAppToken({ token: to, type: tp });
        }
    }, [ auth ]);
    

    const updateAppUser = useCallback(() => {
        getCurrentUserInfoRequest({ auth: authHeader() })
            .then(responseData => {
                if (responseData) {
                    setAppUser({
                        id: responseData?.id,
                        email: responseData?.email,
                        isActive: responseData?.is_active,
                        isVerified: responseData?.is_verified,
                        firstName: responseData?.firstName,
                        lastName: responseData?.lastName,
                        hasSubscription: responseData?.subscription?.status
                            ? responseData?.subscription?.status !== 'never_had'
                            : false,
                        hasVerifiedLinkedin: responseData?.linkedin_profile_is_verified
                    });
                } else {
                    console.log('upd app user');
                    signOut();
                }
            });
    }, [ appToken, setAppUser, signOut ]);

    useEffect(() => {
        //if (appState === APP_STATE.INDEX) {
            if (isAuthenticated()) {
                if (appUser.id) {
                    console.log('- user id', appUser.id);
                    if (appUser.hasSubscription) {
                        console.log('- has subscription', appUser.hasSubscription);
                        if (appUser.hasVerifiedLinkedin) {
                            console.log('- has verified linkedin', appUser.hasVerifiedLinkedin);
                            setAppState(APP_STATE.MAIN);
                            navigate('/dashboard');
                        } else {
                            console.log('= welcome');
                            setAppState(APP_STATE.WELCOME);
                        }
                    } else {
                        console.log('= billing');
                        setAppState(APP_STATE.BILLING);
                        navigate('/billing');
                    }
                } else {
                    updateAppUser();
                }
            } else {
                // setAppState(APP_STATE.LOGIN);
                // navigate('/login');
            }
        //}
    }, [ appState, appUser, setAppState ]);

    return appUser.id ? (
        <div className="Page WelcomePage">
            <Header isSpecialBackground />
            { appToken?.token && <Body userID={appUser.id} /> }
        </div>
    ) : (
        <div className="Page WelcomePage">
            <Header isSpecialBackground />
        </div>
    );
};

const Body = ({ userID }) => {
    return (
        <div className="Page-body WelcomePage-body">
            <div className="Page-card WelcomePage-card">
                <div className="Page-card WelcomePage-card-data">
                    <SetupMaster userID={userID} />
                </div>
            </div>
        </div>
    );
};
