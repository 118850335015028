import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthHeader, useIsAuthenticated, useSignOut } from 'react-auth-kit';
import linkedInTag  from 'react-linkedin-insight';

import { Header } from '../../components/header';
import { LINKEDIN_PARTNER_ID  } from '../../constants';

import { getCurrentUserInfoRequest } from '../../api/v1/users-api';

import { getSteps, updateSteps, getCount, updateCount } from './state-machine/useStorage';

import { creator } from './steps/creator';
// import { Intro } from './steps/intro';
// import { QuestionA } from './steps/question-a';

import scenario from './state-machine/scenario.json';

import '../common/styles.css';
import './styles.css';
import './steps/styles.css';

export const WelcomePage = () => {
    const [ requestGetMeIsLoaded, setRequestGetMeIsLoaded ] = useState(false);
    const [ isVerified, setVerified ] = useState(false);
    const [ userID, setUserID ] = useState('123'); // tmp

    const navigate = useNavigate();
    const authHeader = useAuthHeader();    
    const isAuthenticated = useIsAuthenticated();
    const signOut = useSignOut();
    
    if (!isAuthenticated()) {
        signOut();
        navigate('/login');
    }

    linkedInTag.init(LINKEDIN_PARTNER_ID);

    useEffect(() => {
        getCurrentUserInfoRequest({ auth: authHeader() })
            .then(responseData => {
                if (responseData) { // TODO: add check - is_active
                    setVerified(!!responseData?.is_verified);
                    setRequestGetMeIsLoaded(true);
                } else {
                    signOut();
                    navigate('/login');
                }
            });
    }, [ authHeader, navigate, signOut ]);

    if (requestGetMeIsLoaded) {
        if (isVerified) {
            return (
                <div className="Page WelcomePage">
                    <Header isSpecialBackground />
                    <Body userID={userID} />
                </div>
            );
        } else {
            navigate('/verify');    
        }
    } else {
        return (
            <div className="Page WelcomePage">
                <Header isSpecialBackground />
            </div>
        );
    }
};

const Body = ({ userID }) => {
    const [ elements, setElements ] = useState([]);
    const [ step, setStep ] = useState(null);
    const [ stepInfo, setStepInfo ] = useState(null);

    console.log('step=', step);    

    useEffect(() => {
        const v = userID && getCount(userID); // || 0) : 0;

        if (! v?.count) {
            updateCount({ count: 0 }, userID);
            setStep(0);
        } else {
            setStep(0);;
        }
    }, []);
    useEffect(() => {
        stateMachineIteration();
    }, [ stepInfo ]);
    useEffect(() => {
        console.log('ue: step info', step);
        updateCount({ count: step }, userID);
        if (step !== null && scenario && scenario?.steps?.length > step) {
            const { key, element, interactive, delay, options } = scenario.steps[step];

            console.log(' -', key, element, interactive, delay, options);
            setStepInfo(() => {
                return { key, element, interactive, delay, options };
            });
        }
    }, [ step ]);

    const goNext = (value, i) => {
        console.log('go next', value, step, i);
        if (userID) {
            const storage = getSteps(userID);

            storage[stepInfo?.key] = value ? { selectedOption: value } : {};
            updateSteps(storage, userID);
        }
        setStep(step + 1);
    };

    const stateMachineIteration = () => {
        if (stepInfo) {
            const createElement = creator(stepInfo?.element);

            let fast = false;
            let selectedOption = '';

            if (userID) {
                const storage = getSteps(userID);

                fast = storage[stepInfo?.key] ? true : false;
                selectedOption = storage[stepInfo?.key]?.selectedOption || '';
            }

            const Element = createElement({
                fast: fast,
                selectedOption: selectedOption,
                options: stepInfo.options,
                onStepNext: (v) => {
                    const i = step;

                    goNext(v, i);
                }
            });
                
            Element && setElements(() => {
                return [ ...elements, Element ];
            });
        }
    };

    return (
        <div className="Page-body WelcomePage-body">
            <div className="Page-card WelcomePage-card">
                <div className="Page-card WelcomePage-card-data">
                    {
                        elements.map((item, index) => (
                            <div key={index}>{ item }</div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};
