import React from 'react';
import { Tag } from 'antd';

import './styles.css';

const getColor = (name) => {
    switch (name) {
        case 'Remote':
            return 'cyan';

        case 'Easy apply':
            return 'red';

        case 'Contract':
            return {
                
                border: '1px solid rgb(255, 220, 170)',
                background: 'rgb(255, 237, 211)',
                borderRadius: '10px',
                color: 'rgb(161, 106, 25)'
            };

        case 'Full-time':
            return {
                border: '1px solid #B9E6FE',
                background: '#E9F7FF',
                borderRadius: '10px',
                color: '#317ba2'
            };

        default:
            return {
                border: '1px solid #ccc',
                background: '#eee',
                borderRadius: '10px',
                color: 'gray'
            };
    }
};

export const AboutMapper = ({ about }) => {
    if (! about) {
        return null;
    }

    return (
        <div className="about-mapper">
            { about.map((item, index) => {
                return (
                    <Tag
                        className="about-mapper-tag"
                        key={index}
                        style={getColor(item)}
                    >
                        { item }
                    </Tag>
                );
            }) }
        </div>
    );
};
