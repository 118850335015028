import axios from 'axios';

import {
    SEEKY_API_V1,
    SEEKY_API_V1_LOCATIONS_COUNTRIES_PATH,
    SEEKY_API_V1_LOCATIONS_REGIONS_PATH,
    SEEKY_API_V1_LOCATIONS_CITY_PATH
} from '../../constants';

export const getCountriesRequest = async ({ auth, filterByName }) => {
    const param = filterByName ? `?filter_by_name=${ filterByName }` : '';

    return axios
        .get(`${SEEKY_API_V1}/${SEEKY_API_V1_LOCATIONS_COUNTRIES_PATH}${param}`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => {
            return response?.data || [];
        })
        .catch(() => { return Promise.reject(); });
};

export const getRegionsRequest = async ({ auth, countryName, filterByName }) => {
    if (! countryName) {
        return Promise.reject();
    }

    const country = `${SEEKY_API_V1_LOCATIONS_COUNTRIES_PATH}/${countryName}`;
    const region = `${SEEKY_API_V1_LOCATIONS_REGIONS_PATH}`;
    const param = filterByName ? `?filter_by_name=${ filterByName }` : '';

    return axios
        .get(`${SEEKY_API_V1}/${country}/${region}${param}`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => {
            return response?.data || [];
        })
        .catch(() => { return Promise.reject(); });
};

export const getCitiesRequest = async ({ auth, countryName, regionName, filterByName }) => {
    if (! countryName || ! regionName) {
        return Promise.reject();
    }

    const country = `${SEEKY_API_V1_LOCATIONS_COUNTRIES_PATH}/${countryName}`;
    const region = `${SEEKY_API_V1_LOCATIONS_REGIONS_PATH}/${regionName}`;
    const city = `${SEEKY_API_V1_LOCATIONS_CITY_PATH}`;
    const param = filterByName ? `?filter_by_name=${ filterByName }` : '';

    return axios
        .get(`${SEEKY_API_V1}/${country}/${region}/${city}${param}`,
            { headers: {
                'accept': 'application/json',
                'Authorization': `${ auth }`
            } }
        )
        .then(response => {
            return response?.data || [];
        })
        .catch(() => { return Promise.reject(); });
};
