import React, { useState, useCallback, useEffect } from 'react';
import { Table, Button } from 'antd';

import { CompanyMapper } from './mappers/company-mapper';
import { MatchingMapper } from './mappers/matching-mapper';
import { AboutMapper } from './mappers/about-mapper';
// import { StatusMapper } from './mappers/status-mapper';
import { DocsMapper } from './mappers/docs-mapper';
import { ActionsMapper } from './mappers/actions-mapper';

import {
    useScreenWidth,
    WIDTH_1000_VALUE
} from '../../../../hooks/use-screen-width';

import { requestGetJobsList } from '../../../../api/v1/jobs-api';

import { Status } from './status';

import './styles.css';

const columns = [
    { title: 'Company', dataIndex: 'company', align: 'left' },
    { title: 'Matching', dataIndex: 'matching', align: 'center' },
    { title: 'About', dataIndex: 'about', align: 'left' },
    { title: 'Status', dataIndex: 'status', align: 'left' },
    // { title: 'Date', dataIndex: 'date', align: 'center' },
    { title: 'Docs', dataIndex: 'docs', align: 'center' },
    { title: 'Actions', dataIndex: 'actions', align: 'center' }
];

const mapper = (data, auth) => {
    return data
        .map((item) => {
            const tags = [];

            item?.employment_type && tags.push(item.employment_type);

            return {
                key: item?.user_to_job_id || '',
                company: <CompanyMapper
                    company={{ 
                        title: item?.job_name || '',
                        url: item?.company_url || '',
                        name: item?.company_name || ''
                    }}
                    userToJobId={item?.user_to_job_id || ''}
                />,
                matching: <MatchingMapper
                    matching={item?.score}
                />,
                about: <AboutMapper
                    about={tags}
                />,
                status: <Status
                    auth={auth}
                    jobId={item?.user_to_job_id}
                    initialStatus={item?.status}
                />,
                // date: '',
                docs: <DocsMapper
                    auth={auth}
                    jobId={item?.user_to_job_id}
                    hasCv={item?.has_cv}
                    hasCl={item?.has_cl}
                />,
                actions: <ActionsMapper link={item?.job_url || ''} />
            };
        }) || [];
};

export const SearchesTable = ({ auth }) => {
    const [ startIndex, setStartIndex ] = useState(null);
    const [ endIndex, setEndIndex ] = useState(null);
    const [ data, setData ] = useState([]);
    const [ hasPrev, setPrev ] = useState(false);
    const [ hasNext, setNext ] = useState(false);
    const screenWidth = useScreenWidth();
    
    const getJobsList = useCallback((isPrev = false) => {
        requestGetJobsList({
            auth,
            lessThan: !isPrev ? endIndex : null,
            greaterThan: isPrev ? startIndex : null,
            limit: 10
        })
            .then(response => {
                setStartIndex( response?.start_id || 0 );
                setEndIndex( response?.end_id || 0 );
                setData( response?.items || [] );
                setPrev( response?.has_prev || false );
                setNext( response?.has_next || false );
            })
            .catch(() => {
                // TODO: notification
                console.log('Error: get jobs list');
            })
    }, [ startIndex, endIndex ]);
    
    useEffect(() => {
        if (data.length === 0) {
            getJobsList();
        }
    }, []);

    return (
        <div>
            { screenWidth > WIDTH_1000_VALUE
                ? <Table
                    className="jobs-table"
                    columns={columns}
                    dataSource={mapper(data, auth)}
                    pagination={false}
                />
                : <MobileTable
                    dataSource={mapper(data, auth)}
                />
            }
            <div className="jobs-table-buttons">
                <Button
                    className="jobs-table-button"
                    onClick={() => { getJobsList(true); }}
                    disabled={data.length === 0 || !hasPrev }
                >
                    Prev
                </Button>
                <Button
                    className="jobs-table-button"
                    onClick={() => { getJobsList(); }}
                    disabled={data.length === 0 || !hasNext }
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

const MobileTable = ({ dataSource }) => {
    return <div className="dashboard-mobile-table">
        {
            dataSource.map((item, index) => {
                return (
                    <div
                        className="dmt-item"
                        key={`${index}-${item?.key}`}
                    >
                        <div className="dmt-item-a">
                            { item?.status }
                        </div>
                        <div className="dmt-item-b">
                            <div>
                                { item?.company }
                            </div>
                            <div style={{ scale: '1.3', marginRight: '1em' }}>
                                { item?.matching }
                            </div>                            
                        </div>
                        <div className="dmt-item-c">
                            { item?.about }
                        </div>
                    </div>
                );
            })
        }
    </div>;
}
