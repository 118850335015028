import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

export const DEFAULT_DELAY_MS = 500;
const TRANSITION_TIMEOUT_MS = 500;

export const MessageWrapper = ({ delay = DEFAULT_DELAY_MS, body }) => {
    const [ enabled, setEnabled ] = useState(false);

    useEffect(() => {
        if (delay) {
            const timeoutId = setTimeout(() => {
                setEnabled(true);
            }, delay);

            return () => clearTimeout(timeoutId);
        }
    }, [ delay ]);

    return (
        <Transition in={enabled} timeout={TRANSITION_TIMEOUT_MS}>
            { state => {
                return body(state);
            } }
        </Transition>
    );
};
