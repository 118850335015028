import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { Breadcrumb, Typography, Col, Row, Space, Flex } from 'antd';

import { ReactComponent as HomeIcon } from '../assets/home-icon.svg';
import { ReactComponent as InfoBlockDiagramA } from '../assets/info-block-a.svg';
import { ReactComponent as InfoBlockDiagramB } from '../assets/info-block-b.svg';
import { ReactComponent as InfoBlockDiagramC } from '../assets/info-block-c.svg';

import { AppContext } from '../../../AppContext';
import {
    useScreenWidth,
    WIDTH_800_VALUE
} from '../../../hooks/use-screen-width';

import {
    requestGetJobsTotal,
    requestGetJobsBestPercent
} from '../../../api/v1/jobs-api';
import { requestGetActiveStreak } from '../../../api/v1/active-streak-api';

import { SearchesTable } from './table/table';

import './styles.css';

const { Title } = Typography;

export const Dashboard = ({ auth }) => {
    const screenWidth = useScreenWidth();
    const { appUser } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (navigate) navigate('/dashboard', { replace: true });
    }, [navigate]);

    return (
        <div className="dashboard-page">
            <Space direction="vertical" size='large' style={{ width: '100%' }}>
                <Breadcrumb
                    separator="&nbsp;&nbsp;>&nbsp;&nbsp;"
                    items={[
                        { title: <HomeIcon /> },
                        { title: 'Dashboard' },
                        { title: 'Overview' }

                    ]}
                />
                <div style={{ width: '100%' }}>
                    <Row>
                        <Col flex="auto">
                            <Title level={2} style={{ margin: '0'}}>
                                { appUser?.firstName ? `Welcome, ${appUser?.firstName}` : 'Welcome!' }
                            </Title>
                        </Col>
                        <Col>
                            <Link to='/create'>
                                <Button>+ New Search</Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
                <div className="dashbord-info-block">
                    <Row className="dashboard-info-block-row" gutter={24}>
                        <Col
                            className="gutter-row"
                            span={ screenWidth > WIDTH_800_VALUE ? 8 : 24 }
                        >
                            <TotalVacancyInfoBlock auth={auth} />
                        </Col>
                        <Col
                            className="gutter-row"
                            span={ screenWidth > WIDTH_800_VALUE ? 8 : 24 }
                        >
                            <MatchInfoBlock auth={auth} />
                        </Col>
                        <Col
                            className="gutter-row"
                            span={ screenWidth > WIDTH_800_VALUE ? 8 : 24 }
                        >
                            <ActiveStreakInfoBlock auth={auth} />
                        </Col>
                    </Row>
                </div>
                <div style={{ width: '100%'}}>
                    <SearchesTable auth={auth} />
                </div>
            </Space>
        </div>
    );
};

const TotalVacancyInfoBlock = ({ auth }) => {
    const [ jobsTotal, setJobsTotal ] = useState('');

    useEffect(() => {
        requestGetJobsTotal({ auth })
            .then(total => setJobsTotal(total))
            .catch(() => setJobsTotal(''));
    }, []);

    return (
        <div className="info-block">
            <Space direction="vertical" size='middle' style={{ width: '100%' }}>
                <Title level={5} style={{ margin: '0' }}>
                    Total Vacancy
                </Title>
                <div style={{ position: 'relative' }}>
                    <Flex gap="middle" justify={'space-between'} style={{ width: '100%'}}>
                        <div>
                            <div className="value">
                                <span className="value-large">
                                    { jobsTotal }
                                </span>
                                &nbsp;
                                <span className="value-mini">&nbsp;</span>
                            </div>
                        </div>
                        <div>
                            <InfoBlockDiagramA />
                        </div>
                    </Flex>
                </div>
            </Space>
        </div>
    );
};

const MatchInfoBlock = ({ auth }) => {
    const [ match, setMatch ] = useState('');

    useEffect(() => {
        requestGetJobsBestPercent({ auth })
            .then(total => setMatch(total))
            .catch(() => setMatch(''));
    }, []);

    return (
        <div className="info-block">
            <Space direction="vertical" size='middle' style={{ width: '100%' }}>
                <Title level={5} style={{ margin: '0' }}>
                    Match (≥80%)
                </Title>
                <div style={{ position: 'relative' }}>
                    <Flex gap="middle" justify={'space-between'} style={{ width: '100%'}}>
                        <div>
                            <div className="value">
                                <span className="value-large">
                                    { match }
                                </span>
                                &nbsp;
                                <span className="value-mini">
                                    { match ? '%' : '' }
                                </span>
                            </div>
                        </div>
                        <div>
                            <InfoBlockDiagramB />
                        </div>
                    </Flex>
                </div>
            </Space>
        </div>
    );
};

const ActiveStreakInfoBlock = ({ auth }) => {
    const [ activeStreak, setActiveStreak ] = useState('');
    const [ daysText, setDaysText ] = useState('');

    useEffect(() => {
        requestGetActiveStreak({ auth })
            .then(total => {
                setActiveStreak(total);
                setDaysText(
                    total === 1 ? 'day' : 'days'
                );
            })
            .catch(() => setActiveStreak(''));
    }, []);

    return (
        <div className="info-block">
            <Space direction="vertical" size='middle' style={{ width: '100%' }}>
                <Title level={5} style={{ margin: '0' }}>
                    Active Streak
                </Title>
                <div style={{ position: 'relative' }}>
                    <Flex gap="middle" justify={'space-between'} style={{ width: '100%' }}>
                        <div>
                            <div className="value">
                                <span className="value-large">
                                    { activeStreak }
                                </span>
                                &nbsp;
                                <span className="value-mini">
                                    { activeStreak ? daysText : '' }
                                </span>
                            </div>
                        </div>
                        <div>
                            <InfoBlockDiagramC />
                        </div>
                    </Flex>
                </div>
            </Space>
        </div>
    );
};