import React from 'react';

import { Intro } from './intro';
import { QuestionA } from './step-question-a';
import { QuestionB } from './question-b';
import { VerifyLinkedin } from './verify-linkedin';
import { Advice } from './advice';

export const creator = (element) => {
    switch (element) {
        case 'Intro':
            return (props) => <Intro {...props} />;

        case 'QuestionA':
            return (props) => <QuestionA {...props} />;

        case 'QuestionB':
            return (props) => <QuestionB {...props} />;

        case 'VerifyLinkedin':
            return (props) => <VerifyLinkedin {...props} />;

        case 'Advice':
            return (props) => <Advice {...props} />

        default:
            return () => null;
    }
};
