    import React, { useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';

import { MessageWrapper, SeekyPic } from '../common';

import './styles.css';

export const Advice = ({ fast, options, selectedOption, onStepNext }) => {
    return (
        <div className="message message-advice">
            <div className="message-left">
                <SeekyPic
                    fast={fast}
                    delay={1000}
                />
            </div>
            <div className="message-right">
                <AdviceBlock
                    fast={fast}
                    delay={1000}
                    options={options}
                    selectedOption={selectedOption}
                    onGetAnswer={onStepNext}
                />
            </div>            
        </div>
    );
};

const AdviceBlock = ({ fast, delay, onStepNext }) => {
    return (
        <MessageWrapper
            delay={fast ? 0 : delay}
            body={(state) => (
                <div className={fast ? 'message-breaker message-item-white-fast' : `message-breaker message-item-white message-item--${state}`}>
                    <div className="board-item-spec-gray">
                        <span>Seeky AI</span>
                    </div>
                    <div className="text">Great 👍 I have personalized advice for you on how to improve your LinkedIn profile.</div>
                    
                    <div className='advice-list'>
                        <div className='text'>
                            <div className='title'>Profile header</div>
                            <div className='item'>1. ...</div>
                            <div className='item'>2. ...</div>
                            <div className='item'>3. ...</div>
                        </div>
                        <div className="text">
                            <div className='title'>Profile header</div>
                            <div className='item'>1. ...</div>
                            <div className='item'>2. ...</div>
                            <div className='item'>3. ...</div>
                        </div>
                        <div className="text">
                            <div className='title'>Work experience</div>
                            <div className='item'>1. ...</div>
                            <div className='item'>2. ...</div>
                            <div className='item'>3. ...</div>
                        </div>
                    </div>                    
        
                    <div className='rating-block'>
                        <div className="text">
                            <div className="title">Overall rating</div>
                            <div className="info">Your profile looks like an 8 out of 10</div>
                        </div>
                        <Button onClick={() => onStepNext()}>Go to job search settings</Button>
                    </div>
                </div>
            )}
        />
    );
};
