import React, { Fragment, useState, useEffect } from 'react';

import { Message, FounderPic, handleChangeWithDelay } from '../../common';
import { STATE } from '../../setup-master';

export const Intro = ({ stepKey, stepState, onChange }) => {
    const [ isFast, setFast ] = useState(false);
    const [ isEnabled, setEnabled ] = useState(false);

    useEffect(() => {
        setFast(stepState === STATE.FREEZE);
        setEnabled(stepState === STATE.ACTIVE || stepState === STATE.FREEZE);
    }, [ stepState ]);

    useEffect(() => {
        stepState === STATE.INIT &&
            handleChangeWithDelay(stepKey, STATE.WAIT, {}, onChange, 10);
        stepState === STATE.ACTIVE &&
            handleChangeWithDelay(stepKey, STATE.FREEZE, {}, onChange, 1000);
    }, [ stepKey, stepState ]);

    return isEnabled && (
        <div className="message">
            <div className="message-center">
                <div className="pic">
                    <FounderPic
                        isFast={isFast}
                        delay={300}
                    />
                </div>
                <div>
                    <Message
                        isFast={isFast}
                        delay={300}
                        headMsg={(
                            <Fragment>
                                <span>Antony Balakirev</span>
                                <span>Founder</span>
                            </Fragment>
                        )}
                        bodyMsg={(
                            <Fragment>
                                <div className="text">Welcome to your personal account of the Seeky AI.</div>
                                <div className="text">Thank you for joining us, we will be happy to help you with your dream job search tasks.</div>
                                <div className="text">Thanks, the team Seeky AI.</div>
                            </Fragment>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};
