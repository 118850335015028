import React from 'react';

import { MessageWrapper } from '../common';

import picFounder from '../../assets/founder.png';

export const FounderPic = ({ isFast, delay }) => {
    return (
        <MessageWrapper
            delay={isFast ? 0 : delay}
            body={(state) => (
                <img
                    className={isFast ? 'img-fast ' : `img img--${state}`}
                    src={picFounder}
                    alt="Antony Balakirev"
                />
            )}
        />
    );
};
