import axios from 'axios';

import {
    SEEKY_API_V1_SEARCH_FILTERS_CREATE_URL
} from '../../constants';

/**
  "keywords": "string", // обязательное поле
  "location": {
    "country": "string",
    "region": "string",
    "city": "string" // оказывается, что нужно всега указывать в API
  },
  "experience_level": [
    "No Matter"
  ],
  "job_type": [
    "No Matter"
  ],
  "remote": [
    "No Matter"
  ]
*/

/**
* POST: /api/v1/search_filters
* Creates a search filter
*/
export const requestCreateNewSearch = async ({ data, auth }) => {
    console.log('api: /api/v1/search_filters - create a search filter');

    // data: { job, location: { country, region, city } }
    const { job, location } = data; // experienceLevel, jobType, isRemote 

    return axios
        .post(SEEKY_API_V1_SEARCH_FILTERS_CREATE_URL,
            {
                keywords: job,
                location: {
                    country: location?.country || '',
                    region: location?.region || '',
                    city: location?.city || null
                },
                experienceLevel: [ 'No Matter' ],
                jobType: [ 'No Matter' ],
                remote: [ 'No Matter' ]
            },
            { headers: {
                'content-type': 'application/json',
                'accept': 'application/json',
                'Authorization': auth
            } }
        )
        .then(response => response)
        .catch((e) => {
          const error = {
            code: e?.response?.data?.error_code || 0,
            message: 'Error'
          };

          if (e?.response?.data?.error_code === 14) {
            error.message = 'Sorry! You have maximum search filters and cannot create another one...';
          }

          return Promise.reject(error);
        });
};

/**
 * GET List of search filters.
 */
export const requestGetListOfSearchFilters = async ({ auth }) => {
  return axios
    .get('/api/v1/search_filters',
      { headers: {
        'accept': 'application/json',
        'Authorization': `${ auth }`
      } }
    )
    .then(response => {
      return response?.data;
    })
    .catch(() => Promise.reject());
};


/**
 * PATCH: /api/v1/search_filters/{search_filter_id}/status
 * Change status a user search filter.
 */
export const requestPatchSearchStatusById = async ({ auth, filterId, filterStatus }) => {
  console.log('req -', filterId, '-', filterStatus);
  return axios
    .patch(`/api/v1/search_filters/${ filterId }/status`,
      { status: filterStatus },
      { headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        'Authorization': `${ auth }`
    } })
    .then(response => {
      return response?.data;
    })
    .catch(e => Promise.reject(e?.response));
};
