const STEPS_PREFIX = 'setup_master_stp';
const COUNT_PREFIX = 'setup_master_cnt';

export const getSteps = (userID) => getLS(STEPS_PREFIX, userID);    
export const updateSteps = (data, userID) => updateLS(data, STEPS_PREFIX, userID );

export const getCount = (userID) => getLS(COUNT_PREFIX, userID);
export const updateCount = (data, userID) => updateLS(data, COUNT_PREFIX, userID);

const getLS = (prefix, userID) => {
    let data = {};

    if (! userID || ! prefix) {
        throw new Error('Error! Our state-machine cannot work without userID or prefix.');
    }

    const raw = localStorage.getItem(`${prefix}_${userID}`);

    if (! raw) {
        data = {};
        updateLS(data, prefix, userID);

        return data;
    }

    return JSON.parse(raw);
};

const updateLS = (data, prefix, userID) => {
    if (! userID || ! prefix) {
        throw new Error('Error! Our state-machine cannot work without userID or prefix.');
    }

    localStorage.setItem(`${prefix}_${userID}`, JSON.stringify(data));
};