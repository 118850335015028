import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Typography, Col, Row, Space, Tabs, Checkbox } from 'antd';
import { Button } from 'react-bootstrap';

import { ReactComponent as HomeIcon } from '../assets/home-icon.svg';

import TemplateImg from './assets/template.png';

import './styles.css';

const { Title } = Typography;

export const CV = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (navigate) navigate('/cv', { replace: true });
    }, [navigate]);

    return (
        <div className="cv-page">
            <Space direction="vertical" size='large' style={{ width: '100%' }}>
                <Breadcrumb
                    separator="&nbsp;&nbsp;>&nbsp;&nbsp;"
                    items={[
                        { title: <HomeIcon /> },
                        { title: 'CV Settings' }
                    ]}
                />
                <div style={{ width: '100%'}}>
                    <Row>
                        <Col flex="auto">
                            <Title level={2} style={{ margin: '0'}}>CV Settings</Title>
                        </Col>
                    </Row>
                </div>
            </Space>
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        label: 'Generate',
                        key: '1',
                        children: <TabA />,
                    },
                    {
                        label: 'History (coming soon)',
                        key: '2',
                        children: 'Tab 2',
                        disabled: true,
                    }
                ]}
            />
        </div>
    );
};

const TabA = () => {
    return (            
        <Space direction="vertical" size='large' style={{ width: '100%' }}>
            <div style={{ marginTop: '1em' }}>
                <Title level={5}>
                    Generate CV
                </Title>
                <div>
                    You can choose to have your Linkedin profile generated by default.
                </div>
            </div>
            <div className="info-block">
                <Space direction="vertical" size='small' style={{ width: '100%' }}>
                    <Title level={5} style={{ margin: '0'}}>
                        Generate CV from Linkedin
                    </Title>
                    <div>
                        You can choose to have your Linkedin profile generated by default.
                    </div>
                    <br />
                    <Button>Update Profile Data</Button>
                </Space>
            </div>
            <div>
                <Title level={5}>
                    Settings
                </Title>
                <Space direction="vertical" size='small' style={{ width: '100%' }}>
                    <Checkbox onChange={() => {}}>Follow the job description strictly</Checkbox>
                    <Checkbox disabled onChange={() => {}}>Edit the location in the resume (Coming soon)</Checkbox>
                    <Checkbox disabled onChange={() => {}}>Add years of expearience (Coming soon)</Checkbox>
                </Space>
            </div>
            <div>
                <Title level={5}>
                    Selecting your preffered template
                </Title>
                <img src={TemplateImg} alt="Template #1" />
            </div>
            <br />
        </Space>
    );
};