import React, { Fragment, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Select, Switch } from 'antd';

import { Message, SeekyPic, handleChangeWithDelay } from '../../common';
import { STATE } from '../../setup-master';
import { AppContext } from '../../../../../AppContext';

import { getJobsTitlesRequest } from '../../../../../api/v1/jobs-api';
import {
    getCountriesRequest,
    getRegionsRequest,
    getCitiesRequest
} from '../../../../../api/v1/locations-api';

import './styles.css';

export const Search = ({ stepKey, stepState, stepInfo, onChange }) => {
    const [ isEnabled, setEnabled ] = useState(false);

    useEffect(() => {
        stepState === STATE.INIT &&
            handleChangeWithDelay(stepKey, STATE.WAIT, {}, onChange, 10);
    }, [ stepKey, stepState ]);

    useEffect(() => {
        setEnabled(stepState === STATE.ACTIVE || stepState === STATE.FREEZE);
        // setEnabledBlock(true);
    }, [ stepState ]);

    const handleSubmit = useCallback(() => {
        if (stepState === STATE.ACTIVE) {
            //onChange(stepKey, STATE.FREEZE, {});
        }
    }, []);


    return isEnabled && (
        <div className="message step-search">
            <div className="message-center">
                <div className="pic">
                    <SeekyPic
                        delay={300}
                    />
                </div>
                <div>
                    <Block 
                        delay={300}
                        state={stepState}
                        onChange={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

const Block = ({ delay, state, onChange }) => {
    const blockRef = useRef(null);
    const { appToken } = useContext(AppContext);

    const [ isOk, setOk ] = useState(false);

    const [ job, setJob ] = useState('');
    const [ country, setCountry ] = useState('');
    const [ region, setRegion ] = useState('');
    const [ city, setCity ] = useState('');

    const [ isEnabledRegion, setEnabledRegion ] = useState(false);
    const [ isEnabledCity, setEnabledCity ] = useState(false);

    useEffect(() => { blockRef.current.scrollIntoView({ behavior: 'smooth' }); }, []);
    useEffect(() => { setEnabledRegion(!! country); }, [ country ]);
    useEffect(() => { !isEnabledCity && setCity(''); }, [ isEnabledCity ]);

    useEffect(() => {
        if ((job && country && region && !isEnabledCity) ||
            (job && country && region && isEnabledCity && city)) {
            setOk(true);
        } else {
            setOk(false);
        }
    }, [ job, country, region, city, isEnabledCity ]);

    const handleJob = useCallback(v => {
        setJob(v);
    }, [ setJob ]);
    const handleCountry = useCallback(v => {
        setCountry(v);
        setRegion('');
        setCity('');
        setEnabledCity(false);
    }, [ setCountry ]);
    const handleRegion = useCallback(v => {
        setRegion(v);
        setCity('');
        setEnabledCity(false);
    }, [ setRegion ]);
    const handleSwitch = useCallback(v => {
        setEnabledCity(v)
    }, [ setEnabledCity ]);
    const handleCity = useCallback(v => {
        setCity(v);
    }, [ setCity ]);

    return (
        <div ref={blockRef}>
            <Message
                delay={delay}
                isWhiteMsg
                headMsg={<span>Seeky AI</span>}
                bodyMsg={
                    <Fragment>
                        <div className="text">Now, let's set up the search criteria for suitable vacancies.</div>

                        <SelectJob
                            appToken={appToken}
                            value={job}
                            onSelected={handleJob}
                        />
                        <SelectCountry
                            appToken={appToken}
                            value={country}
                            onSelected={handleCountry}
                        />
                        <SelectRegion
                            appToken={appToken}
                            value={region}
                            isEnabled={isEnabledRegion}
                            countryName={country}
                            onSelected={handleRegion}
                        />
                        <div className="search-item">
                            <Switch
                                disabled={! isEnabledRegion}
                                size="small"
                                onChange={handleSwitch}
                            />
                            <span className="switch-label">I need to choose a city</span>
                        </div>
                        <div className="text">We don’t recommend choosing a city as it gives fewer search results.</div>
                        <SelectCity
                            appToken={appToken}
                            value={city}
                            isEnabled={isEnabledCity}
                            countryName={country}
                            regionName={region}
                            onSelected={handleCity}
                        />
                        <div className="search-btn-border">
                            <Button
                                disabled={! isOk}
                                className="search-btn"
                                onClick={onChange}
                            >
                                Create Search
                            </Button>
                        </div>
                    </Fragment>
                }
            />
        </div>
    );
};

const SelectJob = ({ appToken, value, onSelected }) => {
    const [ jobList, setJobList ] = useState([]);
    const [ current, setCurrent ] = useState(value || '');
    const [ selectedValue, setSelectedValue ] = useState(value || '');

    useEffect(() => {
        if (value !== selectedValue) { setCurrent(value); setSelectedValue(value); }
    }, [ value ]);     

    useEffect(() => { selectedValue && onSelected && onSelected(selectedValue); }, [ selectedValue ]);
    useEffect(() => {
        appToken?.token &&
            getJobsTitlesRequest({ filterByName: current, auth: `${appToken?.type} ${appToken.token}` })
                .then(responseData => {
                    const list = responseData
                        .map(item => {
                            return { value: item, label: item };
                        });

                    setJobList(list);
                })
                .catch(() => { console.log('select job error'); });
    }, [ appToken, setJobList, current ]);

    return (
        <div className="search-item">
            <div className="label">Job Title</div>
            <Select
                value={selectedValue}
                style={{ width: '100%' }}
                showSearch
                placeholder="Select job title"
                optionFilterProp="children"
                onChange={(v) => { setCurrent(v); setSelectedValue(v); }}
                onSearch={(v) => { setCurrent(v); }}
                options={[ ...jobList ]}
                filterOption={() => true}
            />
        </div>
    );
};

const SelectCountry = ({ appToken, value, onSelected }) => {
    const [ countryList, setCountryList ] = useState([]);
    const [ current, setCurrent ] = useState(value || '');
    const [ selectedValue, setSelectedValue ] = useState(value || '');

    useEffect(() => {
        if (value !== selectedValue) { setCurrent(value); setSelectedValue(value); }
    }, [ value ]);

    useEffect(() => { selectedValue && onSelected && onSelected(selectedValue); }, [ selectedValue ]);
    useEffect(() => {
        appToken?.token &&
            getCountriesRequest({ filterByName: current, auth: `${appToken?.type} ${appToken.token}` })
                .then(responseData => {
                    const list = responseData
                        .map(item => {
                            return { value: item, label: item };
                        });

                    setCountryList(list);
                })
                .catch(() => { console.log('select country error'); });
    }, [ appToken, setCountryList, current ]);

    return (
        <div className="search-item">
            <div className="label">Country</div>
            <Select
                style={{ width: '100%' }}
                value={selectedValue}
                showSearch
                placeholder="Select your country"
                optionFilterProp="children"
                onChange={(v) => { setCurrent(v); setSelectedValue(v); }}
                onSearch={(v) => { setCurrent(v); }}
                options={countryList}
                filterOption={() => true}
            />
        </div>
    );
};

const SelectRegion = ({ appToken, value, isEnabled, countryName, onSelected }) => {
    const [ list, setList ] = useState([]);
    const [ current, setCurrent ] = useState(value || '');
    const [ selectedValue, setSelectedValue ] = useState(value || '');

    useEffect(() => {
        if (value !== selectedValue) { setCurrent(value); setSelectedValue(value); }
    }, [ value ]);

    useEffect(() => { selectedValue && onSelected && onSelected(selectedValue); }, [ selectedValue ]);
    useEffect(() => {
        appToken?.token &&
            getRegionsRequest({
                auth: `${appToken?.type} ${appToken.token}`,
                countryName: countryName,
                filterByName: current
            })
                .then(responseData => {
                    setList(
                        responseData
                            .map(item => { return { value: item, label: item }; })
                    );
                })
                .catch(() => { console.log('select region error'); });
    }, [ appToken, setList, countryName, current ]);

    return (
        <div className="search-item">
            <div className="label">Region</div>
            <Select
                disabled={! isEnabled}
                value={selectedValue}
                style={{ width: '100%' }}
                showSearch
                placeholder="Select your region"
                optionFilterProp="children"
                onChange={(v) => { setCurrent(v); setSelectedValue(v); }}
                onSearch={(v) => { setCurrent(v); }}
                options={list}
                filterOption={() => true}
            />
        </div>
    );
};

const SelectCity = ({ appToken, value, isEnabled, countryName, regionName, onSelected }) => {
    const [ list, setList ] = useState([]);
    const [ current, setCurrent ] = useState(value || '');
    const [ selectedValue, setSelectedValue ] = useState(value || '');

    useEffect(() => {
        if (value !== selectedValue) { setCurrent(value); setSelectedValue(value); }
    }, [ value ]);

    useEffect(() => { selectedValue && onSelected && onSelected(selectedValue); }, [ selectedValue ]);
    useEffect(() => {
        appToken?.token &&
            getCitiesRequest({
                auth: `${appToken?.type} ${appToken.token}`,
                countryName: countryName,
                regionName: regionName,
                filterByName: current
            })
                .then(responseData => {
                    setList(
                        responseData
                            .map(item => { return { value: item, label: item }; })
                    );
                })
                .catch(() => { console.log('select city error'); });
    }, [ appToken, setList, countryName, current ]);

    return isEnabled && (
        <div className="search-item">
            <div className="label">City</div>
            <Select
                disabled={! isEnabled}
                value={selectedValue}
                style={{ width: '100%' }}
                showSearch
                placeholder="Select your city"
                optionFilterProp="children"
                onChange={(v) => { setCurrent(v); setSelectedValue(v); }}
                onSearch={(v) => { setCurrent(v); }}
                options={list}
                filterOption={() => true}
            />
        </div>
    );
};
